import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { showLoader } from "../../providers/actions/loader";
import PropTypes from "prop-types";

import { useStripe, useElements } from "@stripe/react-stripe-js";

import BancontactForm from "./BancontactForm";
import SuccessImage from "../../assets/imgs/success.png";

import axios from "axios";
import Button from "@material-ui/core/Button";
import CachedIcon from "@material-ui/icons/Cached";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const BancontactCheckoutForm = ({ data, showLoader }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [successPanel, setSuccessPanel] = useState(false);
  const [msgVisible, setMsgVisible] = useState(false);
  const [errorSuccMsg, setErrorSuccMsg] = useState("");
  const [BancontactMsg, setBancontactMsg] = useState("");
  const [panelLink, setPanelLink] = useState(null);

  useEffect(() => {
    console.log(data);
    if (stripe && data && data.productName !== "") {
      showLoader(true);
      var url = new URL(window.location);
      var paymentClientSecret = url.searchParams.get(
        "payment_intent_client_secret"
      );
      var setupClientSecret = url.searchParams.get(
        "setup_intent_client_secret"
      );

      if (paymentClientSecret) {
        stripe
          ?.retrievePaymentIntent(paymentClientSecret)
          .then(async (response) => {
            if (response.error) {
              // Handle error
              setSuccessPanel(true);
              showLoader(false);
              setBancontactMsg("error");
              console.log("response.error", response.error);
            } else if (
              response.paymentIntent &&
              response.paymentIntent.status === "succeeded"
            ) {
              // handle success
              const params = {
                paymentMethodId: response.paymentIntent.payment_method,
                paymentId: response.paymentIntent.id,
              };
              const re = await updatePaymentMethod(params);

              if (re === true) {
                if (data.spu) {
                  console.log({ "data => ": data });
                  showLoader(false);
                  window.location.href = data.spu;
                } else {
                  showLoader(false);
                  setBancontactMsg("success");
                  setSuccessPanel(true);
                }
              } else {
                showLoader(false);
                console.log(re);
              }
            } else if (
              response.paymentIntent &&
              response.paymentIntent.status !== "succeeded"
            ) {
              showLoader(false);
              setBancontactMsg("error");
              setPanelLink(`/checkout/${data.productSlug}`);
              setSuccessPanel(true);
            }
          });
      } else if (setupClientSecret) {
        // create the subs here
        var setupIntent = url.searchParams.get("setup_intent");
        createSubscription({ setupClientSecret, setupIntent })
          .then((res) => {
            if (data.spu) {
              url.searchParams.delete("setup_intent");
              url.searchParams.delete("setup_intent_client_secret");
              url.searchParams.delete("redirect_status");
              window.location.href = data.spu;
              setBancontactMsg("success");
              setSuccessPanel(true);
              showLoader(false);
              //window.location.href = data.spu;
            } else {
              showLoader(false);
              setBancontactMsg("success");
              setSuccessPanel(true);
              url.searchParams.delete("setup_intent");
              url.searchParams.delete("setup_intent_client_secret");
              url.searchParams.delete("redirect_status");
              window.location = url;
            }
            showLoader(false);
          })
          .catch((error) => {
            console.log(error);
            showLoader(false);
          });
      } else {
        showLoader(false);
      }
    }
  }, [stripe, data, showLoader]);

  const closeCallbackMsgError = () => {
    setMsgVisible(false);
  };

  const handleSubmit = async (event) => {
    showLoader(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const debiDateForm = event.target.debitdate
      ? parseInt(event.target.debitdate.value)
      : parseInt(new Date().getDate());
    var debitDate = "now";

    var dd = debiDateForm;
    var mm = new Date().getMonth() + 1;

    if (debiDateForm < new Date().getDate()) {
      dd = debiDateForm;

      const dateNow = new Date();

      mm = dateNow.getMonth() + 2;

      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      debitDate =
        dateNow.getFullYear() +
        "-" +
        mm +
        "-" +
        dd +
        " " +
        dateNow.getHours() +
        ":" +
        dateNow.getMinutes() +
        ":" +
        dateNow.getSeconds();

      // debitDate = new Date(debitDatet);
    } else if (debiDateForm > new Date().getDate()) {
      dd = debiDateForm;
      const dateNow = new Date();
      mm = dateNow.getMonth() + 1;
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      debitDate =
        dateNow.getFullYear() +
        "-" +
        mm +
        "-" +
        dd +
        " " +
        dateNow.getHours() +
        ":" +
        dateNow.getMinutes() +
        ":" +
        dateNow.getSeconds();
      // debitDate = new Date(debitDatet);
    }

    var regularityNum = null;
    var regularitySetValue = event.target.regularitySet.value;

    if (regularitySetValue === "monthly_specific_number") {
      if (event.target.regularityNum.value) {
        regularityNum = event.target.regularityNum.value;
      } else {
        document.documentElement.scrollTop = 0;
        setMsgVisible(true);
        setErrorSuccMsg(
          "Vous devez sélectionner en combien de fois Vous voulez payer"
        );
        return false;
      }
    } else if (regularitySetValue === "user_choice") {
      if (
        event.target.userchoicereg.value &&
        event.target.userchoicereg.value === "one_time"
      ) {
        regularitySetValue = "one_time";
      } else if (
        event.target.userchoicereg.value &&
        event.target.userchoicereg.value === "monthly"
      ) {
        regularitySetValue = "monthly";
      } else {
        document.documentElement.scrollTop = 0;
        setMsgVisible(true);
        setErrorSuccMsg(
          "Vous devez sélectionner le paiement (En une seul fois ou Mensuel)"
        );
        return false;
      }
    }

    const accountholderName =
      event.target["accountholder-firstname"].value +
      " " +
      event.target["accountholder-lastname"].value;
    const firstname = event.target["accountholder-firstname"].value;
    const lastname = event.target["accountholder-lastname"].value;
    const email = event.target.email.value;
    const phone = event.target.phone.value;
    const city = event.target.city.value;
    const country = event.target.country.value;
    const postalCode = event.target.postalcode.value;
    const address = event.target.address.value;
    const freeAmount = data.price === "free_amount";
    const amount =
      data.price === "free_amount"
        ? event.target.donationFreeAmount.value
        : data.price;

    const plateDonorName = event.target.plateDonorName
      ? event.target.plateDonorName.value
      : null;

    const plateWellName = event.target.plateWellName
      ? event.target.plateWellName.value
      : null;

    const dataSet = {
      accountholderName: accountholderName,
      firstname,
      lastname,
      email,
      phone,
      city,
      country,
      postalCode,
      address,
      regularitySet: regularitySetValue,
      regularityNum: regularityNum,
      freeAmount,
      amount,
      currency: data.currency,
      spu: data.spu,
      productLocalId: data.productId,
      productName: data.productName,
      productCampName: data.campName,
      debitDate,
      plateDonorName,
      plateWellName,
    };

    if (
      (regularityNum && regularityNum > 1) ||
      regularitySetValue === "monthly"
    ) {
      const { clientSecret } = await getClientSecretSetupIntent(dataSet);

      const { error } = await stripe.confirmBancontactSetup(clientSecret, {
        payment_method: {
          billing_details: {
            name: dataSet.accountholderName,
            email: dataSet.email,
          },
        },
        return_url: new URL(window.location),
      });

      if (error) {
        // Show error to your customer.
        console.log(error.message);
        showLoader(false);
        console.log({ data });
      }
    } else {
      const { clientSecret } = await getClientSecret(dataSet);
      const { error } = await stripe.confirmBancontactPayment(clientSecret, {
        payment_method: {
          billing_details: {
            name: accountholderName,
            email: email,
          },
        },
        return_url: new URL(window.location),
      });

      if (error) {
        // Show error to your customer.
        console.log(error.message);
        showLoader(false);
        console.log({ data });
      }
    }
  };

  return (
    <>
      <SuccessPanel open={successPanel} msg={BancontactMsg} link={panelLink} />
      <MSG
        visible={msgVisible}
        msg={errorSuccMsg}
        closeCallback={closeCallbackMsgError}
      />
      <BancontactForm stripe={stripe} data={data} handleSubmit={handleSubmit} />
    </>
  );
};

BancontactCheckoutForm.propTypes = {
  showLoader: PropTypes.func.isRequired,
};

export default connect(null, { showLoader })(BancontactCheckoutForm);

const SuccessPanel = ({ open = false, msg = null, link = null }) => {
  return open ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "white",
        zIndex: 999,
        display: "flex",
      }}
    >
      <div
        align="center"
        style={{ width: "320px", margin: "auto", maxWidth: "95%" }}
      >
        {msg ? (
          <div>
            {msg === "success" ? (
              <>
                <img src={SuccessImage} alt="success" />
                <p style={{ color: "#27ab13" }}>
                  Votre don a bien été enregistré.
                </p>
                <p>
                  Toute l'équipe LIFE vous remercie pour votre contribution.
                </p>
                <a href="http://life-ong.org/" style={{ color: "inherit" }}>
                  LIFE-ONG
                </a>
              </>
            ) : (
              <>
                <p style={{ color: "red" }}>
                  <b>OUPS!</b>
                </p>
                <p>
                  La tentative de paiement a échoué, vérifiez vos informations
                  et rééssayez ou contactez notre relation donateur pour avoir
                  plus de details
                </p>
                {link ? (
                  <p>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      href={link}
                      startIcon={<CachedIcon />}
                    >
                      Réessayez
                    </Button>{" "}
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      href="https://life-ong.org/contactez-nous/"
                      startIcon={<MailOutlineIcon />}
                    >
                      Nous Contacter
                    </Button>
                  </p>
                ) : (
                  <p>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      href="https://life-ong.org/contactez-nous/"
                      startIcon={<MailOutlineIcon />}
                    >
                      Nous Contacter
                    </Button>
                  </p>
                )}
              </>
            )}
          </div>
        ) : (
          <>
            <img src={SuccessImage} alt="success" />
            <p style={{ color: "#27ab13" }}>Votre don a bien été enregistré.</p>
            <p>Toute l'équipe LIFE vous remercie pour votre contribution.</p>
            <a href="http://life-ong.org/" style={{ color: "inherit" }}>
              LIFE-ONG
            </a>
          </>
        )}
      </div>
    </div>
  ) : (
    ""
  );
};

const MSG = ({ visible, msg, closeCallback }) => {
  return (
    <div
      id="notif_error_success_msg"
      style={{
        width: "500px",
        maxWidth: "90%",
        margin: "auto",
        color: "red",
        position: "fixed",
        top: "20px",
        left: "0",
        background: "white",
        borderRadius: "4px",
        zIndex: 99,
        border: "solid 1px red",
        right: "0",
        display: visible ? "block" : "none",
      }}
    >
      <span
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          fontSize: "30px",
          lineHeight: "16px",
          color: "#333",
          cursor: "pointer",
          padding: "2px 5px 5px 5px",
        }}
        onClick={closeCallback}
      >
        &#215;
      </span>
      <ul>
        <li>{msg}</li>
      </ul>
    </div>
  );
};

const getClientSecret = async (customer) => {
  // create user and payment api
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_PUBLIC_BASE_URL}/stripe/bancontact/setup-paymentIntent`,
      {
        name: customer.accountholderName,
        firstname: customer.firstname,
        lastname: customer.lastname,
        email: customer.email,
        phone: customer.phone,
        city: customer.city,
        currency: customer.currency,
        country: customer.country,
        postal_code: customer.postalCode,
        line1: customer.address,
        productLocalId: customer.productLocalId,
        productName: customer.productName,
        amount: customer.amount,
        regularity: customer.regularitySet,
        regularityNum: customer.regularityNum,
        productCampName: customer.productCampName,

        plateDonorName: customer.plateDonorName,
        plateWellName: customer.plateWellName,
      }
    );
    return {
      clientSecret: res.data.clientSecret,
      customerId: res.data.customerId,
    };
  } catch (err) {
    console.error(err);
  }
};

const getClientSecretSetupIntent = async (customer) => {
  // create user and payment api
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_PUBLIC_BASE_URL}/stripe/bancontact/setup-subscription`,
      customer
    );
    return {
      clientSecret: res.data.clientSecret,
      customerId: res.data.customerId,
    };
  } catch (err) {
    console.error(err);
  }
};

const updatePaymentMethod = async (params) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_PUBLIC_BASE_URL}/stripe/bancontact/update-pm`,
      params
    );

    if (res.data.response === "ok") {
      return true;
    }
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

const createSubscription = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_PUBLIC_BASE_URL}/stripe/bancontact/shedule-subscription`,
      data
    );
    if (res.data.response === "ok") {
      return true;
    }
    return res.data;
  } catch (err) {
    console.error(err);
  }
};
