import { useState, useEffect } from "react";
import {
  Button,
  Container,
  FormControl,
  Paper,
  TextField,
} from "@material-ui/core";
import API from "../../../utils/API";
import Loading from "../../../components/Loading";
import { useParams } from "react-router";

import { useTranslation } from "react-i18next";

const UpdateCampaign = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [campName, setCampName] = useState("");
  const [campColor, setCampColor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { history } = props;

  useEffect(() => {
    const getcamp = async () => {
      try {
        const resp = await API.get(
          `${process.env.REACT_APP_API_BASE_URL}/campaign/${id}`
        );
        const data = resp.data[0];

        setCampName(data.name);
        setCampColor(data.color);
      } catch (error) {
        console.log(error);
      }
    };
    getcamp();
    return () => {
      setCampName([]);
    };
  }, [id]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const campName = e.target.campaignName.value;
    let color = e.target.campaignColor.value;
    if (!color) {
      color = "#f36e20";
    }

    try {
      setIsLoading(true);

      const res = await API.put(
        `${process.env.REACT_APP_API_BASE_URL}/campaign/${id}`,
        {
          name: campName,
          color: color,
        }
      );

      alert(res.data.msg);
      history.push("/campaigns");

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleNameChange = (e) => {
    setCampName(e.target.value);
  };
  const handleColorChange = (e) => {
    setCampColor(e.target.value);
  };
  return (
    <Container maxWidth="sm">
      <Loading open={isLoading} />
      <Paper className="paper__padding">
        <h2>{t("campaigns.updatetitle")}</h2>
        <form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
          <FormControl fullWidth={true} className="form__control_margin">
            <TextField
              id="campaignName"
              name="name"
              label={t("campaigns.name")}
              value={campName}
              onChange={handleNameChange}
            />
          </FormControl>
          <FormControl fullWidth={true} className="form__control_margin">
            <TextField
              id="campaignColor"
              name="color"
              value={campColor}
              label={t("campaigns.colorhexa")}
              helperText={t("campaigns.defaulthelpertext")}
              onChange={handleColorChange}
            />
          </FormControl>

          <FormControl fullWidth={true} className="form__control_margin">
            <Button type="submit" variant="contained" color="primary">
              {t("global.save")}
            </Button>
          </FormControl>
        </form>
      </Paper>
    </Container>
  );
};
export default UpdateCampaign;
