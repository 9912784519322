import "./main.css";
import { useState } from "react";
// redux
import { loggIn } from "../../providers/actions";
import { useDispatch, useSelector } from "react-redux";

import { TextField, Paper, Button, FormControl } from "@material-ui/core";

import API from "../../utils/API";
import Loading from "../../components/Loading";

import { useTranslation } from "react-i18next";

const Login = ({ history }) => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector((state) => state.isLogged.isLoggedIn);

  if (isLoggedIn) {
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  }

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const pass = event.target.password.value;

    if (!email && !pass) {
      alert("You must enter your email and your password");
      return false;
    }

    setIsLoading(true);
    try {
      const response = await API.post(`/login_check`, {
        username: email,
        password: pass,
      });

      if (response.data) {
        dispatch(loggIn(response.data));
        setTimeout(() => {
          history.push("/");
        }, 2000);
      } else {
        console.log("error", response);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert("Email or Password are invalid");
    }

    // handle logIn here
    // after success dispatch(loggIn(data.donor));
  };

  return (
    <div className="donate__loginPage__container">
      <Loading open={isLoading} />
      <Paper className="donate__login__paper">
        <h1 align="center">{t("global.login_page")}</h1>
        <form
          noValidate
          autoComplete="on"
          onSubmit={handleLoginSubmit}
          className="donate__login__form"
        >
          <TextField
            name="email"
            className="donate__login__el"
            type="email"
            label={t("global.email")}
          />
          <TextField
            name="password"
            className="donate__login__el"
            type="password"
            label={t("global.password")}
          />
          <FormControl>
            <Button variant="contained" color="primary" type="submit">
              {t("global.login")}
            </Button>
          </FormControl>
        </form>
      </Paper>
    </div>
  );
};

export default Login;
