import { useState, useEffect } from "react";

import Table from "../../components/Table";
import Loading from "../../components/Loading";
import API from "../../utils/API";
import FormatCurrency from "../../components/FormatCurrency";
import FormatDate from "../../components/FormatDate";
import StatusBadge from "../../components/StatusBadge";
import SearchDateInterval from "../../components/SearchDateInterval";

import Button from "@material-ui/core/Button";
import ImportExportIcon from "@material-ui/icons/ImportExport";

import { useTranslation } from "react-i18next";

const Payments = () => {
  const { t } = useTranslation();
  const [payments, setPayments] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPayments = async () => {
      try {
        setIsLoading(true);
        const data = await API.get(
          `${process.env.REACT_APP_API_BASE_URL}/payments`
        );
        const pays = data.data;
        pays.map((p, index) => {
          p.amount = <FormatCurrency amount={p.amount} />;
          p.createdAt = <FormatDate date={p.createdAt.date} />;
          p.status = <StatusBadge status={p.status} size={11} />;
          return p;
        });
        setPayments(pays);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getPayments();
    setColumns([
      { title: t("tablecolumns.name"), field: "customer.name" },
      { title: t("tablecolumns.email"), field: "customer.email" },
      { title: t("tablecolumns.product"), field: "product.name" },
      { title: t("tablecolumns.amount"), field: "amount" },
      { title: t("tablecolumns.gatewey"), field: "getway" },
      { title: t("tablecolumns.type"), field: "paymentMethod.type" },
      { title: t("tablecolumns.status"), field: "status" },
      {
        title: t("tablecolumns.created_at"),
        field: "createdAt",
        type: "date",
      },
    ]);
    return () => {
      setPayments([]);
    };
  }, [t]);

  const handleSearchDateCallback = async (from, to) => {
    setIsLoading(true);
    const fromFormated =
      new Date(from).getFullYear() +
      "-" +
      (new Date(from).getMonth() + 1) +
      "-" +
      new Date(from).getDate();

    const toFormated =
      new Date(to).getFullYear() +
      "-" +
      (new Date(to).getMonth() + 1) +
      "-" +
      new Date(to).getDate();

    try {
      const res = await API.get(
        `${process.env.REACT_APP_API_BASE_URL}/payments/${fromFormated}/${toFormated}`
      );
      const pays = res.data;
      pays.map((p, index) => {
        p.amount = <FormatCurrency amount={p.amount} />;
        p.createdAt = <FormatDate date={p.createdAt.date} />;
        p.status = <StatusBadge status={p.status} size={11} />;
        return p;
      });
      setPayments(pays);
      setIsLoading(false);
      // console.log(res);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleExportData = () => {

    try { 
      var csv = "";

      const headers = Object.keys(payments[0]);

      csv += headers.join(',');
      csv += "\r\n";

      // (B) ARRAY TO CSV STRING
     
      for (let payment of payments) {
        for (let col in payment) { 
          if(col === "amount"){
            csv += payment[col].props.amount + ","; 
          }else if(col === "createdAt"){
            csv += payment[col].props.date + ","; 
          }else if(col === "customer"){
            csv += payment[col].email + ","; 
          }else if(col === "paymentMethod"){
            csv += payment[col] ? (payment[col].getwayId + ",") : (" ,") ; 
          }else if(col === "product"){
            csv += payment[col].name + ","; 
          }else if(col === "status"){
            csv += payment[col].props.status + ","; 
          }else if(col === "metadata"){
            csv += " ,"; 
          }else{
          csv += payment[col] + ","; }
        }
        csv += "\r\n";
      }

      // (C) CREATE BLOB OBJECT

      var myBlob = new Blob([csv], {type: "text/csv"});
    
      // (D) CREATE DOWNLOAD LINK
      var url = window.URL.createObjectURL(myBlob);
      var anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "donate_payments.csv";
      // (E) "FORCE DOWNLOAD"
      // NOTE: MAY NOT ALWAYS WORK DUE TO BROWSER SECURITY
      // BETTER TO LET USERS CLICK ON THEIR OWN
      anchor.click();
      window.URL.revokeObjectURL(url);
      anchor.remove();

    } catch (error) {

      console.error(error);

    }
   
    
  }
  return (
    <div>
      <Loading open={isLoading} />

      <SearchDateInterval callback={handleSearchDateCallback} />
      <Button startIcon={<ImportExportIcon />}  variant="contained" color="primary" onClick={handleExportData} style={{marginBottom: "20px"}}>Exporter en CSV</Button>

      <Table
        tableTitle={t("global.payments")}
        columns={columns}
        data={payments}
        actions={["details"]}
        apiUrl={`${process.env.REACT_APP_API_BASE_URL}/payments`}
        baseUrl={"/payments/"}
      />
    </div>
  );
};
export default Payments;
