import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { Grid, Paper } from "@material-ui/core";

import FormatDate from "../../../components/FormatDate";
import Loading from "../../../components/Loading";
import StatusBadge from "../../../components/StatusBadge";
import API from "../../../utils/API";
import FormatCurrency from "../../../components/FormatCurrency";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const CustomerDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [customerName, setCustomerName] = useState("");
  const [customerFirstName, setCustomerFirstName] = useState("");
  const [customerLastName, setCustomerLastName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerCity, setCustomerCity] = useState("");
  const [customerCountry, setCustomerCountry] = useState("");
  const [customerPostalCode, setCustomerPostalCode] = useState("");
  const [customerCretaedAt, setCustomerCretaedAt] = useState("");
  const [customerGetwayId, setCustomerGetwayId] = useState("");
  const [customerPayments, setCustomerPayments] = useState([]);
  const [customerSubscriptions, setCustomerSubscriptions] = useState([]);
  const [customerPaymentMethods, setCustomerPaymentMethods] = useState([]);

  useEffect(() => {
    const getDetails = async () => {
      setIsLoading(true);
      try {
        const res = await API.get(
          `${process.env.REACT_APP_API_BASE_URL}/customer/${id}`
        );

        setCustomerName(res.data[0].name);
        setCustomerFirstName(res.data[0].firstName);
        setCustomerLastName(res.data[0].lastName);
        setCustomerEmail(res.data[0].email);
        setCustomerPhone(res.data[0].phone);
        setCustomerAddress(res.data[0].addressline1);
        setCustomerCity(res.data[0].city);
        setCustomerCountry(res.data[0].country);
        setCustomerPostalCode(res.data[0].postalCode);
        setCustomerCretaedAt(res.data[0].createdAt.date);
        setCustomerGetwayId(res.data[0].getwayId);
        var datapayments = [];
        res.data[0].payments.map((payment, index) => {
          if (payment.regularity === "one_time_payment")
            datapayments.push(payment);

          return payment;
        });
        // console.log(datapayments);
        setCustomerPayments(datapayments);
        setCustomerSubscriptions(res.data[0].subscriptions);
        setCustomerPaymentMethods(res.data[0].paymentMethods);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getDetails();
  }, [id]);
  return (
    <div>
      <Loading open={isLoading} />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <Paper style={{ padding: "20px" }}>
            <h2>{t("customers.details_title")}</h2>
            <table>
              <tbody>
                <tr>
                  <td colSpan="1">
                    <b>{t("customers.gateway_id")}:</b>
                  </td>
                  <td colSpan="2">
                    <a
                      href={`${process.env.REACT_APP_PUBLIC_STRIPE_BASE_URL}/customers/${customerGetwayId}`}
                      target="_blank"
                      rel="noreferrer"
                      className="router__link"
                      style={{ color: "#3f51b5", fontWeight: "bold" }}
                    >
                      {customerGetwayId}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td colSpan="1">
                    <b>{t("customers.name")} :</b>
                  </td>
                  <td colSpan="2">{customerName}</td>
                </tr>
                <tr>
                  <td colSpan="1">
                    <b>{t("customers.first_name")}:</b>
                  </td>
                  <td colSpan="2">{customerFirstName}</td>
                </tr>
                <tr>
                  <td colSpan="1">
                    <b>{t("customers.last_name")}</b>
                  </td>
                  <td colSpan="2">{customerLastName}</td>
                </tr>
                <tr>
                  <td colSpan="1">
                    <b>{t("customers.email")}:</b>
                  </td>
                  <td colSpan="2">{customerEmail}</td>
                </tr>
                <tr>
                  <td colSpan="1">
                    <b>{t("customers.phone")} :</b>
                  </td>
                  <td colSpan="2">{customerPhone}</td>
                </tr>
                <tr>
                  <td colSpan="1">
                    <b>{t("customers.address")} :</b>
                  </td>
                  <td colSpan="2">{customerAddress}</td>
                </tr>
                <tr>
                  <td colSpan="1">
                    <b>{t("customers.city")} :</b>
                  </td>
                  <td colSpan="2">{customerCity}</td>
                </tr>
                <tr>
                  <td colSpan="1">
                    <b>{t("customers.country")} :</b>
                  </td>
                  <td colSpan="2">{customerCountry}</td>
                </tr>
                <tr>
                  <td colSpan="1">
                    <b>{t("customers.postal_code")} :</b>
                  </td>
                  <td colSpan="2">{customerPostalCode}</td>
                </tr>

                <tr>
                  <td colSpan="1">
                    <b>{t("customers.created_at")} :</b>
                  </td>
                  <td colSpan="2">
                    <FormatDate date={customerCretaedAt} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Paper style={{ padding: "20px" }}>
            <h3>{t("customers.payment_method")} </h3>
            <table style={{ width: "100%" }}>
              <tbody>
                {customerPaymentMethods && customerPaymentMethods.length > 0 ? (
                  customerPaymentMethods.map((paymentMethod, index) => (
                    <tr key={index}>
                      {/* <td>#{paymentMethod.id}</td> */}
                      <td>{paymentMethod.type}</td>
                      <td>{"••••" + paymentMethod.last4}</td>
                      <td>
                        <FormatDate date={paymentMethod.createdAt.date} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>{t("global.no_payment")}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Paper>
        </Grid>
      </Grid>
      <Paper style={{ padding: "20px", marginTop: "20px" }}>
        <h3>{t("global.payments")}</h3>
        <table style={{ width: "100%" }}>
          <tbody>
            {customerPayments && customerPayments.length > 0 ? (
              customerPayments.map((payment, index) => (
                <tr key={index}>
                  <td>#{index + 1}</td>
                  <td>
                    <FormatCurrency amount={payment.amount} />
                  </td>
                  <td>{payment.getway}</td>
                  <td>{payment.product ? payment.product.name : ""}</td>
                  {/* <td>{payment.regularity}</td> */}
                  <td>
                    <StatusBadge status={payment.status} />
                  </td>
                  <td>
                    <FormatDate date={payment.createdAt.date} />
                  </td>
                  <td>
                    <Link to={`/payments/${payment.id}`}>
                      <VisibilityIcon style={{ fontSize: "14px" }} />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>{t("global.no_payment")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Paper>
      <Paper style={{ padding: "20px", marginTop: "20px" }}>
        <h3>{t("global.subscriptions")}</h3>
        <table style={{ width: "100%" }}>
          <tbody>
            {customerSubscriptions && customerSubscriptions.length > 0 ? (
              customerSubscriptions.map((subscription, index) => (
                <tr key={index}>
                  <td>#{index + 1}</td>
                  <td>
                    <FormatCurrency amount={subscription.amount} />
                  </td>

                  <td>{subscription.getway}</td>
                  <td>
                    {subscription.product ? subscription.product.name : ""}
                  </td>
                  {/* <td>{subscription.regularity}</td> */}
                  <td>
                    <StatusBadge status={subscription.status} />
                  </td>
                  <td>
                    <FormatDate date={subscription.createdAt.date} />
                  </td>
                  <td>
                    <Link to={`/subscriptions/${subscription.id}`}>
                      <VisibilityIcon style={{ fontSize: "14px" }} />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>{t("global.no_subscription")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Paper>
    </div>
  );
};

export default CustomerDetails;
