import { useEffect, useState } from "react";
import { useParams } from "react-router";
import API from "../../../utils/API";
import { Container, Paper, Grid } from "@material-ui/core";
import Loading from "../../../components/Loading";
import VisibilityIcon from "@material-ui/icons/Visibility";
// import StatusBadge from "../../../components/StatusBadge";
// import FormatDate from "../../../components/FormatDate";
// import { Link } from "react-router-dom";
// import FormatCurrency from "../../../components/FormatCurrency";

import { useTranslation } from "react-i18next";

const ShowProduct = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState({});
  // const [productPayments, setProductPayments] = useState([]);
  // const [subscriptions, setSubscriptions] = useState([]);
  const [paymentsNumber, setPaymentsNumber] = useState([]);
  const [subscriptionsNumber, setSubscriptionsNumber] = useState([]);

  useEffect(() => {
    const getDetails = async () => {
      try {
        setIsLoading(true);
        const res = await API.get(
          `${process.env.REACT_APP_PUBLIC_BASE_URL}/products/${id}`
        );
        setProduct(res.data.product);
        setPaymentsNumber(res.data.productPaymentsTotalNumber);
        setSubscriptionsNumber(res.data.productSubscriptionsTotalNumber);

        // setSubscriptions(res.data.product.subscriptions);
        // var paymentsData = [];
        // res.data.product.payments.map((payment, index) => {
        //   if (payment.regularity === "one_time_payment")
        //     paymentsData.push(payment);

        //   return payment;
        // });
        // setProductPayments(paymentsData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        alert("ERROR; check console or contact DEV team");
      }
    };
    getDetails();
    return () => {
      setProduct({});
    };
  }, [id]);
  return (
    <Container>
      <Loading open={isLoading} />
      <Paper style={{ padding: "20px" }}>
        <h2>{t("productShow.title")}</h2>
        <p>
          <b>{t("productShow.gatewayId")}:</b>{" "}
          <a
            href={`${process.env.REACT_APP_PUBLIC_STRIPE_BASE_URL}/products/${product.getwayId}`}
            target="_blank"
            rel="noreferrer"
            className="router__link"
            style={{ color: "#3f51b5", fontWeight: "bold" }}
          >
            {product.getwayId}
          </a>
        </p>
        <p>
          <b>{t("productShow.name")}:</b> {product.name}
        </p>
        <p>
          <b>{t("productShow.price")} :</b> {product.price}
        </p>
        <p>
          <b>{t("productShow.currency")} :</b> {product.currency}
        </p>
        <p>
          <b>{t("productShow.regularity")} :</b>{" "}
          {product.regularity === "monthly_specific_number"
            ? product.regularity + "(" + product.regularityChoices + ")"
            : product.regularity}
        </p>
        <p>
          <b>{t("productShow.success_url")} :</b> {product.successPageUrl}
          {product.successPageUrl ? (
            <a
              href={product.successPageUrl}
              target="_blank"
              rel="noreferrer"
              style={{ color: "inherit" }}
            >
              <VisibilityIcon
                size="small"
                style={{
                  position: "absolute",
                  margin: "-1px 10px",
                }}
              />
            </a>
          ) : (
            ""
          )}
        </p>
        <p>
          <b>{t("productShow.campaign")} : </b>
          {product.campaign ? (
            <span style={{ color: product.campaign.color }}>
              {product.campaign.name}
            </span>
          ) : (
            "No campaign attributed"
          )}
        </p>
      </Paper>

      <div style={{ width: "100%", margin: "20px auto 0" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12} sm={4} md={4}>
            <Paper style={{ padding: "20px", textAlign: "center" }}>
              <b>{t("productShow.productPaymentsTotalNumber")}</b>
              <br />
              {paymentsNumber}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Paper style={{ padding: "20px", textAlign: "center" }}>
              <b>{t("productShow.productSubscriptionsTotalNumber")}</b>
              <br />
              {subscriptionsNumber}
            </Paper>
          </Grid>
        </Grid>
      </div>
      {/* <Paper style={{ padding: "20px", marginTop: "20px" }}>
        <h2>{t("productShow.payments")} </h2>
        <table style={{ width: "100%", tableLayout: "fixed" }}>
          <tbody>
            {productPayments && productPayments.length > 0 ? (
              productPayments.map((payment, index) => (
                <tr key={index}>
                  <td>
                    <FormatCurrency amount={payment.amount} />
                  </td>
                  <td>{payment.getway}</td>
                  <td>
                    <Link
                      to={`/customers/${payment.customer.id}`}
                      className="router__link"
                      style={{ color: "#3f51b5", fontWeight: "bold" }}
                    >
                      {payment.customer.name}
                    </Link>
                  </td>
                  <td>{payment.regularity}</td>
                  <td align="center">
                    <StatusBadge status={payment.status} />
                  </td>
                  <td>
                    <FormatDate date={payment.createdAt.date} />
                  </td>
                  <td>
                    <Link to={`/payments/${payment.id}`}>
                      <VisibilityIcon style={{ fontSize: "14px" }} />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>{t("global.no_payment")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Paper>
      <Paper style={{ padding: "20px", marginTop: "20px" }}>
        <h2>{t("productShow.subscriptions")}</h2>
        <table style={{ width: "100%", tableLayout: "fixed" }}>
          <tbody>
            {subscriptions ? (
              subscriptions.map((subscription, index) => (
                <tr key={index} style={{}}>
                  <td style={{ width: "calc(100% / 6)" }}>
                    <FormatCurrency amount={subscription.amount} />
                  </td>
                  <td>{subscription.getway}</td>
                  <td>
                    <Link
                      to={`/customers/${subscription.customer.id}`}
                      className="router__link"
                      style={{ color: "#3f51b5", fontWeight: "bold" }}
                    >
                      {subscription.customer.name}
                    </Link>
                  </td>
                  <td>
                    {subscription.count ? (
                      subscription.count + "X"
                    ) : (
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                        }}
                      >
                        ∞
                      </span>
                    )}
                  </td>
                  <td>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      {subscription.step}
                    </span>
                  </td>
                  <td>
                    <StatusBadge status={subscription.status} />
                  </td>
                  <td>
                    <FormatDate date={subscription.createdAt.date} />
                  </td>
                  <td>
                    <Link to={`/subscriptions/${subscription.id}`}>
                      <VisibilityIcon style={{ fontSize: "14px" }} />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>{t("global.no_subscription")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Paper>
     */}
    </Container>
  );
};

export default ShowProduct;
