import { useEffect, useState } from "react";
import API from "../../utils/API";
import { Grid, Paper } from "@material-ui/core";
import "./main.css";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  const [donorsNum, setDonorsNum] = useState(0);
  const [prodNum, setProdNum] = useState(0);
  const [paysNum, setPaysNum] = useState(0);
  const [subsNum, setSubsNum] = useState(0);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API.get(
          `${process.env.REACT_APP_API_BASE_URL}/dashboard`
        );
        setDonorsNum(response.data.data.customers);
        setProdNum(response.data.data.products);
        setPaysNum(response.data.data.payments);
        setSubsNum(response.data.data.subscriptions);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);
  return (
    <>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item md={3} sm={6} xs={6}>
          <Paper className="home__card__container">
            <div className="home__card__Num">
              <span>{donorsNum}</span>
            </div>
            <p>{t("dashboard.Donors")}</p>
          </Paper>
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <Paper className="home__card__container">
            <div className="home__card__Num">
              <span>{prodNum}</span>
            </div>
            <p>{t("dashboard.Products")}</p>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item md={3} sm={6} xs={6}>
          <Paper className="home__card__container">
            <div className="home__card__Num">
              <span>{paysNum}</span>
            </div>
            <p>{t("dashboard.Succeeded_Payments")}</p>
          </Paper>
        </Grid>
        <Grid item md={3} sm={6} xs={6}>
          <Paper className="home__card__container">
            <div className="home__card__Num">
              <span>{subsNum}</span>
            </div>
            <p>{t("dashboard.Active_Subscriptions")}</p>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
