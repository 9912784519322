import { useState } from "react";
import {
  Button,
  Container,
  FormControl,
  Paper,
  TextField,
} from "@material-ui/core";
import API from "../../../utils/API";
import Loading from "../../../components/Loading";

import { useTranslation } from "react-i18next";

const NewCampaign = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { history } = props;

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const campName = e.target.campaignName.value;
    let color = e.target.campaignColor.value;
    if (!color) {
      color = "#f36e20";
    }
    try {
      setIsLoading(true);

      const res = await API.post(
        `${process.env.REACT_APP_API_BASE_URL}/campaigns`,
        {
          name: campName,
          color: color,
        }
      );

      alert(res.data.msg);
      history.push("/campaigns");

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  return (
    <Container maxWidth="sm">
      <Loading open={isLoading} />
      <Paper className="paper__padding">
        <h2>{t("campaigns.newtitle")}</h2>
        <form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
          <FormControl fullWidth={true} className="form__control_margin">
            <TextField
              id="campaignName"
              name="name"
              label={t("campaigns.name")}
            />
          </FormControl>

          <FormControl fullWidth={true} className="form__control_margin">
            <TextField
              id="campaignColor"
              name="color"
              label={t("campaigns.colorhexa")}
              helperText={t("campaigns.defaulthelpertext")}
            />
          </FormControl>

          <FormControl fullWidth={true} className="form__control_margin">
            <Button type="submit" variant="contained" color="primary">
              {t("global.save")}
            </Button>
          </FormControl>
        </form>
      </Paper>
    </Container>
  );
};
export default NewCampaign;
