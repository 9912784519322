import { useEffect, useState } from "react";

import Table from "../../components/Table";
import Loading from "../../components/Loading";

import API from "../../utils/API";

import { useTranslation } from "react-i18next";

const Customers = () => {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const loadCustomers = async () => {
      try {
        const data = await API.get("/customers");
        const custs = data.data.map((sdata) => {
          sdata.createdAt = sdata.createdAt.date;
          return sdata;
        });
        setCustomers(custs);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    loadCustomers();
    setColumns([
      { title: t("customers.name"), field: "name" },
      { title: t("customers.email"), field: "email" },
      { title: t("customers.phone"), field: "phone" },
      { title: t("customers.created_at"), field: "createdAt", type: "date" },
    ]);
  }, [t]);

  return (
    <div>
      <Loading open={isLoading} />
      <Table
        tableTitle={t("customers.title")}
        columns={columns}
        data={customers}
        actions={["details"]}
        apiUrl={`${process.env.REACT_APP_API_BASE_URL}/customers`}
        baseUrl={"/customers/"}
      />
    </div>
  );
};

export default Customers;
