const StatusBadge = ({ status, size = 13 }) => {
  if (status) {
    var bgColor = "#ec3434";
    if (
      status.toLowerCase() === "active" ||
      status.toLowerCase() === "succeeded"
    ) {
      bgColor = "#3a933a";
    } else if (status.toLowerCase() === "finished") {
      bgColor = "#91c791";
    } else if (
      status.toLowerCase() === "pending" ||
      status.toLowerCase() === "refund pending" ||
      status.toLowerCase() === "processing" ||
      status.toLowerCase() === "scheduled"
    ) {
      bgColor = "#a7a7ac";
    } else if (status.toLowerCase() === "paused") {
      bgColor = "#ffc800";
    }
  }

  return (
    <span
      style={{
        color: "#ffffff",
        fontSize: `${size}px`,
        background: bgColor,
        borderRadius: "2px",
        padding: "2px 6px",
        fontWeight: "bold",
      }}
    >
      {capitalize(status)}
    </span>
  );
};

export default StatusBadge;

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
