import { SHOW_LOADING } from "./types";

export const showLoader =
  (isLoading = false) =>
  (dispatch) => {
    dispatch({
      type: SHOW_LOADING,
      payload: { isLoading },
    });
  };
