import React, { forwardRef, useEffect, useState } from "react";
import MaterialTable from "@material-table/core";
import API from "../../utils/API";
import { useHistory } from "react-router-dom";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Loading from "../Loading";

import { useTranslation } from "react-i18next";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

const Table = (props) => {
  const { t } = useTranslation();

  let history = useHistory();

  const { tableTitle, columns, data, actions, apiUrl, baseUrl, pageSize } =
    props;

  const [actionsArray, setActionsArray] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTableData(data);
    let newActionArray = [];
    actions.map((action) => {
      if (action === "delete") {
        newActionArray.push({
          icon: forwardRef((props, ref) => (
            <DeleteOutline style={{ fontSize: "14px" }} {...props} ref={ref} />
          )),
          tooltip: t("table.delete"),
          onClick: async (event, rowData) => {
            // Do save operation
            let res = window.confirm(
              "Do you really want to delete this record?"
            );
            if (res) {
              try {
                setIsLoading(true);
                const resp = await API.delete(`${apiUrl}/${rowData.id}`);
                alert(resp.data.msg);
                setTableData(tableData.splice(rowData.tableData.id, 1));
                setIsLoading(false);
              } catch (error) {
                console.error(error);
                setIsLoading(false);
              }
            }
          },
        });
      } else if (action === "edit") {
        newActionArray.push({
          icon: forwardRef((props, ref) => (
            <EditIcon style={{ fontSize: "14px" }} {...props} ref={ref} />
          )),
          tooltip: t("table.edit"),
          onClick: (event, rowData) => {
            // Do save operation

            history.push(baseUrl + rowData.id + "/update");
          },
        });
      } else if (action === "details") {
        newActionArray.push({
          icon: forwardRef((props, ref) => (
            <VisibilityIcon style={{ fontSize: "14px" }} {...props} ref={ref} />
          )),
          tooltip: t("table.details"),
          onClick: (event, rowData) => {
            history.push(baseUrl + rowData.id);
          },
        });
      } else if (action === "copy") {
        newActionArray.push({
          icon: forwardRef((props, ref) => (
            <FileCopyIcon style={{ fontSize: "14px" }} {...props} ref={ref} />
          )),
          tooltip: t("table.copy"),
          onClick: (event, rowData) => {
            // Create a "hidden" input
            var aux = document.createElement("input");

            // Assign it the value of the specified element
            aux.setAttribute(
              "value",
              document.location.origin + "/checkout/" + rowData.slug
            );

            // Append it to the body
            document.body.appendChild(aux);

            // Highlight its content
            aux.select();

            // Copy the highlighted text
            document.execCommand("copy");

            // Remove it from the body
            document.body.removeChild(aux);
            alert("Product url Copied");
          },
        });
      } else if (action === "preview") {
        newActionArray.push({
          icon: forwardRef((props, ref) => (
            <OpenInNewIcon style={{ fontSize: "14px" }} {...props} ref={ref} />
          )),
          tooltip: t("table.preview"),
          onClick: (event, rowData) => {
            window.open("/checkout/" + rowData.slug);
            // history.push(
            //   "/payment/" + rowData.id + "/" + rowData.name.replace(/\s/g, "-")
            // );
          },
        });
      }
      return action;
    });

    setActionsArray(newActionArray);
  }, [actions, apiUrl, data, tableData, history, baseUrl, t]);

  return (
    <>
      <Loading open={isLoading} />
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={tableData}
        title={tableTitle}
        options={{
          pageSize: pageSize ? pageSize : 10,
          pageSizeOptions: [5, 10, 20, 50],
          actionsColumnIndex: -1,
        }}
        actions={actionsArray}
        localization={{
          body: {
            emptyDataSourceMessage: t("table.emptyDataSourceMessage"),
            addTooltip: t("table.addTooltip"),
            deleteTooltip: t("table.deleteTooltip"),
            editTooltip: t("table.editTooltip"),
            filterRow: {
              filterTooltip: t("table.filterTooltip"),
            },
            editRow: {
              deleteText: t("table.deleteText"),
              cancelTooltip: t("table.cancelTooltip"),
              saveTooltip: t("table.saveTooltip"),
            },
          },
          grouping: {
            placeholder: t("table.placeholder"),
            groupedBy: t("table.groupedBy"),
          },
          header: {
            actions: t("table.actions"),
          },
          pagination: {
            labelDisplayedRows: t("table.labelDisplayedRows"),
            labelRowsSelect: t("table.labelRowsSelect"),
            labelRowsPerPage: t("table.labelRowsPerPage"),
            firstAriaLabel: t("table.firstAriaLabel"),
            firstTooltip: t("table.firstTooltip"),
            previousAriaLabel: t("table.previousAriaLabel"),
            previousTooltip: t("table.previousTooltip"),
            nextAriaLabel: t("table.nextAriaLabel"),
            nextTooltip: t("table.nextTooltip"),
            lastAriaLabel: t("table.lastAriaLabel"),
            lastTooltip: t("table.lastTooltip"),
          },
          toolbar: {
            addRemoveColumns: t("table.addRemoveColumns"),
            nRowsSelected: t("table.nRowsSelected"),
            showColumnsTitle: t("table.showColumnsTitle"),
            showColumnsAriaLabel: t("table.showColumnsAriaLabel"),
            exportTitle: t("table.exportTitle"),
            exportAriaLabel: t("table.exportAriaLabel"),
            exportName: t("table.exportName"),
            searchTooltip: t("table.searchTooltip"),
            searchPlaceholder: t("table.searchPlaceholder"),
          },
        }}
      />
    </>
  );
};

export default Table;
