import React from "react";
import { Switch, Redirect } from "react-router-dom";

import RouteWithLayout from "./RouteWithLayout";
import FrontRouteWithLayout from "./FrontRouteWithLayout";
import DefaultLayout from "../layouts/DefaultLayout";
import FrontLayout from "../layouts/FrontLayout";
import NotFound from "../layouts/404";

import { Route } from "react-router-dom";

import Dashboard from "../views/Dashboard";
import Checkout from "../views/Chekout";
import CheckoutBancontact from "../views/CheckoutBancontact";
import CheckoutSepa from "../views/CheckoutSepa";
import CheckoutOld from "../views/Chekout/CheckoutOld";
import CheckoutNewOld from "../views/Chekout/CheckoutNewOld";
import Login from "../views/Login";
import Products from "../views/Products";
import NewProduct from "../views/Products/NewProduct";
import UpdateProduct from "../views/Products/UpdateProduct";
import Customers from "../views/Customers";
import CustomerDetails from "../views/Customers/CustomerDetails";
import Campaigns from "../views/Campaigns";
import NewCampaign from "../views/Campaigns/NewCampaign";
import UpdateCampaign from "../views/Campaigns/UpdateCampaign";
import ShowCampaign from "../views/Campaigns/ShowCampaign";
import ShowProduct from "../views/Products/ShowProduct";
import Payments from "../views/Payments";
import Subscriptions from "../views/Subscriptions";
import ShowPayment from "../views/Payments/ShowPayment";
import ShowSubscription from "../views/Subscriptions/ShowSubscription";
import Settings from "../views/Settings";

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={Dashboard}
        exact
        layout={DefaultLayout}
        path="/"
      />
      <RouteWithLayout
        component={Products}
        exact
        layout={DefaultLayout}
        path="/products"
      />
      <RouteWithLayout
        component={NewProduct}
        exact
        layout={DefaultLayout}
        path="/products/new"
      />
      <RouteWithLayout
        component={UpdateProduct}
        exact
        layout={DefaultLayout}
        path="/products/:id/Update"
      />
      <RouteWithLayout
        component={ShowProduct}
        exact
        layout={DefaultLayout}
        path="/products/:id"
      />
      <RouteWithLayout
        component={Customers}
        exact
        layout={DefaultLayout}
        path="/customers"
      />
      <RouteWithLayout
        component={CustomerDetails}
        exact
        layout={DefaultLayout}
        path="/customers/:id"
      />
      <RouteWithLayout
        component={Campaigns}
        exact
        layout={DefaultLayout}
        path="/campaigns"
      />
      <RouteWithLayout
        component={NewCampaign}
        exact
        layout={DefaultLayout}
        path="/campaigns/new"
      />
      <RouteWithLayout
        component={UpdateCampaign}
        exact
        layout={DefaultLayout}
        path="/campaigns/:id/Update"
      />
      <RouteWithLayout
        component={ShowCampaign}
        exact
        layout={DefaultLayout}
        path="/campaigns/:id"
      />
      <RouteWithLayout
        component={Payments}
        exact
        layout={DefaultLayout}
        path="/payments"
      />
      <RouteWithLayout
        component={ShowPayment}
        exact
        layout={DefaultLayout}
        path="/payments/:id"
      />
      <RouteWithLayout
        component={Subscriptions}
        exact
        layout={DefaultLayout}
        path="/subscriptions"
      />
      <RouteWithLayout
        component={ShowSubscription}
        exact
        layout={DefaultLayout}
        path="/subscriptions/:id"
      />
      <RouteWithLayout
        component={Settings}
        exact
        layout={DefaultLayout}
        path="/settings"
      />
      <FrontRouteWithLayout
        component={CheckoutNewOld}
        exact
        layout={FrontLayout}
        path="/payment/:id/:name"
      />
      <FrontRouteWithLayout
        component={Checkout}
        exact
        layout={FrontLayout}
        path="/checkout/:slug"
      />
            <FrontRouteWithLayout
        component={CheckoutBancontact}
        exact
        layout={FrontLayout}
        path="/checkout/:slug/BANCONTACT"
      />
         <FrontRouteWithLayout
        component={CheckoutSepa}
        exact
        layout={FrontLayout}
        path="/checkout/:slug/SEPA"
      />


      <Route component={CheckoutOld} exact path="/payment/:id" />
      <Route component={NotFound} exact path="/not-found" />
      <Route component={Login} exact path="/admin/login" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
