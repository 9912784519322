import {
  TextField,
  Paper,
  Container,
  FormControl,
  Button,
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import Loading from "../../../components/Loading";
import API from "../../../utils/API";
import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

const NewProduct = (props) => {
  const { history } = props;

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [productSlug, setProductSlug] = useState("");

  const [campaigns, setCampaigns] = useState([]);
  const [campaign, setCampaign] = useState("");
  const handleCampaignChange = (e) => {
    setCampaign(e.target.value);
  };
  const [pcVisible, setPcVisible] = useState(false);
  const [monthlyWithChoices, setMonthlyWithChoices] = useState({
    monthlyWithChoice1: false,
    monthlyWithChoice2: false,
    monthlyWithChoice3: false,
    monthlyWithChoice4: false,
    monthlyWithChoice5: false,
    monthlyWithChoice10: false,
  });
  const handleMWCChange = (event) => {
    setMonthlyWithChoices({
      ...monthlyWithChoices,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    const getCampaigns = async () => {
      setIsLoading(true);
      const res = await API.get(
        `${process.env.REACT_APP_API_BASE_URL}/campaigns`
      );
      setCampaigns(res.data);

      setIsLoading(false);
    };

    getCampaigns();
    return () => {
      setCampaigns([]);
    };
  }, []);
  // const [pca, setPca] = useState("#f36e20");
  const [succUrl, setSuccUrl] = useState("https://life-ong.org/remerciement/");
  const handleSuccUrlChange = (e) => {
    setSuccUrl(e.target.value);
  };

  const [notifUrl, setNotifUrl] = useState("");
  const handleNotifUrlChange = (e) => {
    setNotifUrl(e.target.value);
  };

  const [priceDisabled, setPriceDisabled] = useState(false);
  const [isFreeAmount, setIsFreeAmount] = useState(false);
  const handleIsFAChange = (e) => {
    setIsFreeAmount(!isFreeAmount);
    setPriceDisabled(!priceDisabled);
  };
  const [plateDonorWellName, setPlateDonorWellName] = useState(false);
  const handlePlateDonorWellNameChange = (e) => {
    setPlateDonorWellName(!plateDonorWellName);
  };

  const [regularity, setRegularity] = useState("one_time");
  const handleRegularityChange = (e) => {
    setRegularity(e.target.value);
    if (e.target.value === "monthly_specific_number") {
      setPcVisible(true);
    } else {
      Object.keys(monthlyWithChoices).forEach((key) => {
        monthlyWithChoices[key] = false;
      });
      setPcVisible(false);
    }
  };

  const [currency, setCurrency] = useState("eur");
  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  const handleNameChange = (e) => {
    var str = e.target.value;
    const slug = generateSlug(str);
    setProductSlug(slug);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    var paymentChoices = [];
    if (regularity === "monthly_specific_number") {
      var thereIsTrue = 0;

      Object.keys(monthlyWithChoices).forEach((key) => {
        const el = document.getElementsByName(key)[0];
        if (el.checked) {
          paymentChoices.push(el.value);
          thereIsTrue++;
        }
      });
      if (!thereIsTrue) {
        alert("Please select Choices");
        return false;
      }
    }

    if (!e.target.name.value) {
      alert("Name is REQUIRED");
      return false;
    }

    const name = e.target.name.value;
    const slug = e.target.slug.value;
    const notifUrl = e.target.notificationUrl.value;
    let price = e.target.price.value;
    const spu = e.target.successPageUrl.value;
    const freeAmount = e.target.freeAmount.checked;
    const includePlateDonorWellName = e.target.plateDonorWellName.checked;
    // const pca = e.target.productColorAccent.value;
    const campId = campaign;

    if (freeAmount) {
      price = "free_amount";
    } else if (!price) {
      alert("Price is REQUIRED");
      return false;
    }

    try {
      setIsLoading(true);
      const res = await API.post(`/products`, {
        name,
        slug,
        price,
        currency: currency.toLowerCase(),
        regularity,
        spu,
        regularityChoices: paymentChoices,
        notifUrl,
        campaign: campId,
        includePlateDonorWellName,
      });

      setIsLoading(false);
      alert(res.data.msg);
      history.push("/products");
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  return (
    <Container maxWidth="sm">
      <Loading open={isLoading} />
      <Paper className="paper__padding">
        <h1>{t("productNewUpdate.title")}</h1>
        <form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
          <FormControl fullWidth={true} className="form__control_margin">
            <TextField
              id="productName"
              name="name"
              label={t("productNewUpdate.name")}
              onBlur={handleNameChange}
            />
          </FormControl>
          <FormControl fullWidth={true} className="form__control_margin">
            <TextField
              id="productSlug"
              name="slug"
              label={t("productNewUpdate.slug")}
              disabled
              value={productSlug}
            />
          </FormControl>
          <FormControl fullWidth={true} className="form__control_margin">
            <TextField
              type="number"
              id="productPrice"
              name="price"
              label={t("productNewUpdate.price")}
              disabled={priceDisabled}
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="freeAmount"
                  color="primary"
                  value="freeAmount"
                  checked={isFreeAmount}
                  onChange={handleIsFAChange}
                />
              }
              label={t("productNewUpdate.free_amount")}
            />
          </FormControl>
          <FormControl fullWidth={true} className="form__control_margin">
            <InputLabel id="select-currency-label">
              {t("productNewUpdate.currency")}
            </InputLabel>
            <Select
              labelId="select-currency-label"
              id="select_currency"
              value={currency}
              onChange={handleCurrencyChange}
            >
              <MenuItem value={"eur"}>eur (EURO €)</MenuItem>
              <MenuItem value={"usd"} disabled>
                usd (USD $)
              </MenuItem>
            </Select>

            {/* <TextField id="productCurrency" name="currency" label=" Currency" />
            <FormHelperText id="my-helper-text">
              ISO Code Lowercase Currency (For euro 'eur').
            </FormHelperText> */}
          </FormControl>
          <FormControl fullWidth={true} className="form__control_margin">
            <InputLabel id="select-regularity-label">
              {t("productNewUpdate.regularity")}
            </InputLabel>
            <Select
              labelId="select-regularity-label"
              id="select_regularity"
              value={regularity}
              onChange={handleRegularityChange}
            >
              <MenuItem value={"one_time"}>
                {t("productNewUpdate.one_time_payment")}
              </MenuItem>
              <MenuItem value={"monthly_specific_number"}>
                {t("productNewUpdate.monthly_with_choices")}
              </MenuItem>
              <MenuItem value={"monthly"}>
                {t("productNewUpdate.monthly_payment")}
              </MenuItem>
              <MenuItem value={"user_choice"}>
                {t("productNewUpdate.user_choice_monthly_onetime")}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl
            style={pcVisible ? { display: "block" } : { display: "none" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="monthlyWithChoice1"
                  color="primary"
                  value="1"
                  checked={monthlyWithChoices.monthlyWithChoice1}
                  onChange={handleMWCChange}
                />
              }
              label="1X"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="monthlyWithChoice2"
                  color="primary"
                  value="2"
                  checked={monthlyWithChoices.monthlyWithChoice2}
                  onChange={handleMWCChange}
                />
              }
              label="2X"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="monthlyWithChoice3"
                  color="primary"
                  value="3"
                  checked={monthlyWithChoices.monthlyWithChoice3}
                  onChange={handleMWCChange}
                />
              }
              label="3X"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="monthlyWithChoice4"
                  color="primary"
                  value="4"
                  checked={monthlyWithChoices.monthlyWithChoice4}
                  onChange={handleMWCChange}
                />
              }
              label="4X"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="monthlyWithChoice5"
                  color="primary"
                  value="5"
                  checked={monthlyWithChoices.monthlyWithChoice5}
                  onChange={handleMWCChange}
                />
              }
              label="5X"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="monthlyWithChoice10"
                  color="primary"
                  value="10"
                  checked={monthlyWithChoices.monthlyWithChoice10}
                  onChange={handleMWCChange}
                />
              }
              label="10X"
            />
          </FormControl>

          <FormControl fullWidth={true} className="form__control_margin">
            <InputLabel id="select-campaign-label">
              {t("productNewUpdate.campaign")}
            </InputLabel>
            <Select
              labelId="select-campaign-label"
              id="select_campaign"
              value={campaign}
              onChange={handleCampaignChange}
            >
              {campaigns.map((campaign, index) => (
                <MenuItem key={index} value={campaign.id}>
                  {campaign.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth={true} className="form__control_margin">
            <TextField
              type="text"
              id="successPageUrl"
              name="successPageUrl"
              label={t("productNewUpdate.success_page_URL")}
              value={succUrl}
              onChange={handleSuccUrlChange}
            />
          </FormControl>
          <FormControl fullWidth={true} className="form__control_margin">
            <TextField
              type="text"
              id="notificationUrl"
              name="notificationUrl"
              label={t("productNewUpdate.notification_Url")}
              value={notifUrl}
              onChange={handleNotifUrlChange}
            />
          </FormControl>

          <FormControl fullWidth={true} className="form__control_margin">
            <FormControlLabel
              control={
                <Checkbox
                  name="plateDonorWellName"
                  color="primary"
                  value="includePlateDonorWellName"
                  checked={plateDonorWellName}
                  onChange={handlePlateDonorWellNameChange}
                />
              }
              label={t("productNewUpdate.includePlateDonorWellName")}
            />
          </FormControl>
          {/* <FormControl fullWidth={true} className="form__control_margin">
            <TextField
              id="productColorAccent"
              name="productColorAccent"
              label="Accent color (Hexadecimal)"
              placeholder="#f1f1f1"
              value={pca}
              onChange={(e) => setPca(e.target.value)}
            />
          </FormControl> */}
          <FormControl fullWidth={true} className="form__control_margin">
            <Button type="submit" variant="contained" color="primary">
              {t("productNewUpdate.Save")}
            </Button>
          </FormControl>
        </form>
      </Paper>
    </Container>
  );
};

export default NewProduct;

const generateSlug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa€·/_,:;";
  var to =
    "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAae------";

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};
