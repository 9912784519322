import React, { useState } from "react";
import { useStripe, useElements, IbanElement } from "@stripe/react-stripe-js";
import SuccessImage from "../../assets/imgs/success.png";
import IbanForm from "./IbanForm";

import axios from "axios";
import Loading from "../Loading";

export default function IbanCheckoutForm(props) {
  const { data } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [successPanel, setSuccessPanel] = useState(false);
  const [msgVisible, setMsgVisible] = useState(false);
  const [errorSuccMsg, setErrorSuccMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const setPaymentUpGetClientSecret = async (customer) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BASE_URL}/stripe/paymentintent-iban`,
        {
          name: customer.accountholderName,
          firstname: customer.firstname,
          lastname: customer.lastname,
          email: customer.email,
          phone: customer.phone,
          city: customer.city,
          country: customer.country,
          postal_code: customer.postalCode,
          line1: customer.address,
          amount: customer.amount,
          currency: customer.currency,
          regularity: customer.regularitySet,
          regularityNum: customer.regularityNum,
          productName: customer.productName,
          productId: customer.productLocalId,
          productCampName: customer.productCampName,
          plateDonorName: customer.plateDonorName,
          plateWellName: customer.plateWellName,
        }
      );

      const clientSecret = res.data.clientSecret;
      const customerId = res.data.customerId;
      return { clientSecret, customerId };
    } catch (err) {
      console.error(err);
    }
  };

  const getClientSecret = async (customer) => {
    // create user and payment api
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BASE_URL}/stripe/setup-iban`,
        {
          name: customer.accountholderName,
          firstname: customer.firstname,
          lastname: customer.lastname,
          email: customer.email,
          phone: customer.phone,
          city: customer.city,
          country: customer.country,
          postal_code: customer.postalCode,
          line1: customer.address,
          productLocalId: customer.productLocalId,
          productName: customer.productName,
          amount: customer.amount,
          regularity: customer.regularitySet,
          regularityNum: customer.regularityNum,
          productCampName: customer.productCampName,

          plateDonorName: customer.plateDonorName,
          plateWellName: customer.plateWellName,
        }
      );
      return {
        clientSecret: res.data.clientSecret,
        customerId: res.data.customerId,
      };
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const accountholderName =
      event.target["accountholder-firstname"].value +
      " " +
      event.target["accountholder-lastname"].value;
    const firstname = event.target["accountholder-firstname"].value;
    const lastname = event.target["accountholder-lastname"].value;
    const email = event.target.email;
    const phone = event.target.phone;
    const city = event.target.city;
    const country = event.target.country;
    const postalCode = event.target.postalcode;
    const address = event.target.address;
    const regularitySet = event.target.regularitySet;
    const plateDonorName = event.target.plateDonorName;
    const plateWellName = event.target.plateWellName;
    const debiDateForm = event.target.debitdate
      ? parseInt(event.target.debitdate.value)
      : parseInt(new Date().getDate());

    var debitDate = "now";

    var dd = debiDateForm;
    var mm = new Date().getMonth() + 1;

    if (debiDateForm < new Date().getDate()) {
      dd = debiDateForm;

      const dateNow = new Date();

      mm = dateNow.getMonth() + 2;

      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      debitDate =
        dateNow.getFullYear() +
        "-" +
        mm +
        "-" +
        dd +
        " " +
        dateNow.getHours() +
        ":" +
        dateNow.getMinutes() +
        ":" +
        dateNow.getSeconds();

      // debitDate = new Date(debitDatet);
    } else if (debiDateForm > new Date().getDate()) {
      dd = debiDateForm;
      const dateNow = new Date();
      mm = dateNow.getMonth() + 1;
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      debitDate =
        dateNow.getFullYear() +
        "-" +
        mm +
        "-" +
        dd +
        " " +
        dateNow.getHours() +
        ":" +
        dateNow.getMinutes() +
        ":" +
        dateNow.getSeconds();
      // debitDate = new Date(debitDatet);
    }

    var regularityNum = null;
    var regularitySetValue = event.target.regularitySet.value;
    if (regularitySet.value === "monthly_specific_number") {
      if (event.target.regularityNum.value) {
        regularityNum = event.target.regularityNum.value;
      } else {
        document.documentElement.scrollTop = 0;
        setMsgVisible(true);
        setErrorSuccMsg(
          "Vous devez sélectionner en combien de fois Vous voulez payer"
        );
        return false;
      }
    } else if (regularitySet.value === "user_choice") {
      if (
        event.target.userchoicereg.value &&
        event.target.userchoicereg.value === "one_time"
      ) {
        regularitySetValue = "one_time";
      } else if (
        event.target.userchoicereg.value &&
        event.target.userchoicereg.value === "monthly"
      ) {
        regularitySetValue = "monthly";
      } else {
        document.documentElement.scrollTop = 0;
        setMsgVisible(true);
        setErrorSuccMsg(
          "Vous devez sélectionner le paiement (En une seul fois ou Mensuel)"
        );
        return false;
      }
    }

    const customer = {
      accountholderName: accountholderName,
      firstname: firstname,
      lastname: lastname,
      email: email.value,
      phone: phone.value,
      city: city.value,
      country: country.value,
      postalCode: postalCode.value,
      address: address.value,
      regularitySet: regularitySetValue,
      regularityNum: regularityNum,
      freeAmount: data.price === "free_amount",
      amount:
        data.price === "free_amount"
          ? event.target.donationFreeAmount.value
          : data.price,
      currency: data.currency,
      spu: data.spu,
      productLocalId: data.productId,
      productName: data.productName,
      productCampName: data.campName,
      debitDate: debitDate,
      plateDonorName: plateDonorName ? plateDonorName.value : "",
      plateWellName: plateWellName ? plateWellName.value : "",
    };

    const paymentDetails = {
      productLocalId: data.productId,
      freeAmount: data.price === "free_amount",
      amount:
        data.price === "free_amount"
          ? event.target.donationFreeAmount.value
          : data.price,
      currency: data.currency,
      spu: data.spu,
    };

    const iban = elements.getElement(IbanElement);

    if (iban._empty) {
      document.documentElement.scrollTop = 0;
      setMsgVisible(true);
      setErrorSuccMsg("Vous devez saisir votre IBAN");
      return false;
    }

    if (
      (regularityNum && regularityNum > 1) ||
      regularitySetValue === "monthly"
    ) {
      setIsLoading(true);
      const { clientSecret, customerId } = await getClientSecret(customer);

      const result = await stripe.confirmSepaDebitSetup(clientSecret, {
        payment_method: {
          sepa_debit: iban,
          billing_details: {
            name: accountholderName,
            email: email.value,
          },
        },
      });

      if (result.error) {
        // // Show error to your customer.
        document.documentElement.scrollTop = 0;
        setIsLoading(false);
        setMsgVisible(true);
        setErrorSuccMsg(result.error.message);
        return false;
      } else {
        const params = {
          productLocalId: paymentDetails.productLocalId,
          paymentMethodId: result.setupIntent.payment_method,
          paymentIntentId: result.setupIntent.payment_method,
          freeAmount: paymentDetails.freeAmount,
          customerGetwayId: customerId,
          amount: paymentDetails.amount,
          regularityNum: customer.regularityNum,
          regularityType: customer.regularitySet,
          currency: paymentDetails.currency,
          spu: paymentDetails.spu,
          debitDate: debitDate,
          plateDonorName: customer.plateDonorName,
          plateWellName: customer.plateWellName,
        };
        const re = await submitSchedule(params);

        if (re === true) {
          setIsLoading(false);
          if (customer.spu) {
            window.location.href = customer.spu;
          } else {
            setIsLoading(false);
            setSuccessPanel(true);
            //success msg
          }
        } else {
          setIsLoading(false);
          console.log(re);
        }
      }
    } else {
      setIsLoading(true);
      const { clientSecret, customerId } = await setPaymentUpGetClientSecret(
        customer
      );
      const result = await stripe.confirmSepaDebitPayment(clientSecret, {
        payment_method: {
          sepa_debit: iban,
          billing_details: {
            name: accountholderName,
            email: email.value,
          },
        },
      });
      if (result.error) {
        // Show error to your customer.
        document.documentElement.scrollTop = 0;
        setIsLoading(false);
        setMsgVisible(true);
        setErrorSuccMsg(result.error.message);
        return false;
      } else {
        const params = {
          paymentMethodId: result.paymentIntent.payment_method,
          customerGetwayId: customerId,
          paymentId: result.paymentIntent.id,
        };
        const re = await updatePaymentMethod(params);

        if (re === true) {
          if (customer.spu) {
            setIsLoading(false);
            window.location.href = customer.spu;
          } else {
            setIsLoading(false);
            // success msg
            setSuccessPanel(true);
          }
        } else {
          setIsLoading(false);
          console.log(re);
        }
      }
    }
  };

  const updatePaymentMethod = async (params) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BASE_URL}/stripe/update-pm-iban`,
        params
      );

      if (res.data.response === "ok") {
        return true;
      }
      return res.data;
    } catch (err) {
      console.error(err);
    }
  };

  const submitSchedule = async (params) => {
    // console.log(params);
    // return false;
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BASE_URL}/stripe/schedule-iban`,
        params
      );

      if (res.data.status === "active") {
        return true;
      }
      return res.data;
    } catch (err) {
      console.error(err);
    }
  };

  const closeCallbackMsgError = () => {
    setMsgVisible(false);
  };
  return (
    <>
      <Loading open={isLoading} />
      <SuccessPanel open={successPanel} />
      <MSG
        visible={msgVisible}
        msg={errorSuccMsg}
        closeCallback={closeCallbackMsgError}
      />
      <IbanForm onSubmit={handleSubmit} disabled={!stripe} data={data} />
    </>
  );
}

const SuccessPanel = ({ open = false }) => {
  return open ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "white",
        zIndex: 999,
        display: "flex",
      }}
    >
      <div
        align="center"
        style={{ width: "320px", margin: "auto", maxWidth: "95%" }}
      >
        <img src={SuccessImage} alt="success" />
        <p style={{ color: "#27ab13" }}>Votre don a bien été enregistré.</p>
        <p>Toute l'équipe LIFE vous remercie pour votre contribution.</p>
        <a href="http://life-ong.org/" style={{ color: "inherit" }}>
          LIFE-ONG
        </a>
      </div>
    </div>
  ) : (
    ""
  );
};

const MSG = ({ visible, msg, closeCallback }) => {
  return (
    <div
      id="notif_error_success_msg"
      style={{
        width: "500px",
        maxWidth: "90%",
        margin: "auto",
        color: "red",
        position: "fixed",
        top: "20px",
        left: "0",
        background: "white",
        borderRadius: "4px",
        zIndex: 99,
        border: "solid 1px red",
        right: "0",
        display: visible ? "block" : "none",
      }}
    >
      <span
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          fontSize: "30px",
          lineHeight: "16px",
          color: "#333",
          cursor: "pointer",
          padding: "2px 5px 5px 5px",
        }}
        onClick={closeCallback}
      >
        &#215;
      </span>
      <ul>
        <li>{msg}</li>
      </ul>
    </div>
  );
};
