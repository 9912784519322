import { useEffect, useState } from "react";

import { Container, Paper } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { useParams } from "react-router";
import { Link } from "react-router-dom";

import API from "../../../utils/API";
import Loading from "../../../components/Loading";
import FormatDate from "../../../components/FormatDate";
import FormatCurrency from "../../../components/FormatCurrency";

import { useTranslation } from "react-i18next";

const ShowCampaign = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [campaign, setCampaign] = useState({});

  useEffect(() => {
    const getDetails = async () => {
      try {
        setIsLoading(true);
        const res = await API.get(
          `${process.env.REACT_APP_API_BASE_URL}/campaign/${id}`
        );

        setCampaign(res.data[0]);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        alert("ERROR; heck console or contact DEV team");
        console.log(error);
      }
    };
    getDetails();
    return () => {
      setCampaign({});
    };
  }, [id]);

  return (
    <Container>
      <Loading open={isLoading} />
      <Paper style={{ padding: "20px" }}>
        <h2>{t("campaigns.detailstitle")}</h2>
        <p>
          <b>{t("campaigns.name")} :</b> {campaign.name}
        </p>
        <p>
          <b>{t("campaigns.color")} :</b> {campaign.color}
        </p>
      </Paper>
      <Paper style={{ padding: "20px", marginTop: "20px" }}>
        <h2>{t("global.products")}</h2>
        <table style={{ width: "100%" }}>
          <tbody>
            {campaign.products && campaign.products.length > 0 ? (
              campaign.products.map((product, index) => (
                <tr key={index} style={{ borderBottom: "solid 1px #333" }}>
                  <td>{product.name}</td>
                  <td>
                    <FormatCurrency amount={product.price} />
                  </td>
                  <td>
                    <FormatDate date={product.createdAt.date} />
                  </td>
                  <td>
                    <Link to={`/products/${product.id}`}>
                      <VisibilityIcon size="small" />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>{t("global.no_products")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Paper>
    </Container>
  );
};

export default ShowCampaign;
