import axios from "axios";

var baseURL = process.env.REACT_APP_API_BASE_URL;

const API = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.response.use(
  (res) => res,
  (err) => {
    // console.log("err", err);
    // if (err.response.data.error === "Unauthorized") {
    // }
    return Promise.reject(err);
  }
);

export default API;
