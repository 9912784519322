import { useEffect } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router";

function CheckoutNewOld() {
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    const getData = async () => {
      const data = await axios.get(
        `${process.env.REACT_APP_PUBLIC_BASE_URL}/products/${id}`
      );
      if (data.data.product) {
        history.push(`/checkout/${data.data.product.slug}`);
      } else {
        history.push(`/not-found`);
      }
    };
    getData();
  }, [id, history]);
  return <></>;
}

export default CheckoutNewOld;
