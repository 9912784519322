import React from "react";
import ChoiceCss from "./main.module.css";
import BancontactLogo from "../../assets/logos/bancontact.png";
import IbanLogo from "../../assets/logos/sepa-iban.png";

import Tooltip from "@material-ui/core/Tooltip";

import InfoIcon from "@material-ui/icons/Info";
import { useHistory } from "react-router-dom";

const PaymentMethodChoice = ({ paymentMethodCallback, link }) => {
  const history = useHistory();
  const handlePaymentMethodClick = (e) => {
    history.push(link + "/" + e.target.getAttribute("data-ptitle").toLowerCase());
    return;
    // redirect(link + "/" + e.target.getAttribute("data-ptitle").toLowerCase());
    // window.location.href=(link + "/" + e.target.getAttribute("data-ptitle").toLowerCase())
    // paymentMethodCallback(e.target.getAttribute("data-ptitle"));

  };
  return (
    <div className={ChoiceCss.pageContainer}>
      <div className={ChoiceCss.container}>
        <h2>Choisir votre méthode de paiement</h2>
        <div className={ChoiceCss.containerInner}>
          <div
            className={ChoiceCss.box}
            data-ptitle="SEPA"
            onClick={handlePaymentMethodClick}
            style={{ backgroundImage: `url(${IbanLogo})` }}
          >
            <div className={ChoiceCss.boxInfo}>
              <Tooltip title="Prélevement bancaire">
                <InfoIcon />
              </Tooltip>
            </div>
          </div>
          <div
            className={ChoiceCss.box}
            data-ptitle="BANCONTACT"
            onClick={handlePaymentMethodClick}
            style={{ backgroundImage: `url(${BancontactLogo})` }}
          >
            <div className={ChoiceCss.boxInfo}>
              <Tooltip title="Paiement Bancontact Belgique">
                <InfoIcon />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodChoice;
