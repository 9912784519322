import { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import Table from "../../components/Table";
import Loading from "../../components/Loading";
import API from "../../utils/API";
import FormatDate from "../../components/FormatDate";

import { useTranslation } from "react-i18next";

const Campaigns = () => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getCampaigns = async () => {
      setIsLoading(true);
      try {
        const res = await API.get(
          `${process.env.REACT_APP_API_BASE_URL}/campaigns`
        );
        const campaigns = res.data.map((data) => {
          data.createdAt = <FormatDate date={data.createdAt.date} />;
          return data;
        });
        setCampaigns(campaigns);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    getCampaigns();

    setColumns([
      { title: t("campaigns.name"), field: "name" },
      { title: t("campaigns.color"), field: "color" },
      {
        title: t("campaigns.created_at"),
        field: "createdAt",
        type: "date",
      },
    ]);

    return () => {
      setCampaigns([]);
      setColumns([]);
    };
  }, [t]);

  return (
    <div>
      <Loading open={isLoading} />
      <div align="right" style={{ marginBottom: "20px" }}>
        <Link to="/campaigns/new" className="router__link" variant="contained">
          <Button
            startIcon={<AddIcon />}
            color="primary"
            size="medium"
            variant="contained"
          >
            {t("campaigns.add_new")}
          </Button>
        </Link>
      </div>
      <Table
        tableTitle={t("campaigns.title")}
        columns={columns}
        data={campaigns}
        actions={["delete", "edit", "details"]}
        apiUrl={`${process.env.REACT_APP_API_BASE_URL}/campaign`}
        baseUrl={"/campaigns/"}
      />
    </div>
  );
};

export default Campaigns;
