import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Button, Container, Grid, Paper } from "@material-ui/core";

import Loading from "../../../components/Loading";
import API from "../../../utils/API";
import FormatDate from "../../../components/FormatDate";
import FormatCurrency from "../../../components/FormatCurrency";
import StatusBadge from "../../../components/StatusBadge";
import { Link } from "react-router-dom";

import "./index.css";
import CancelPayment from "../../../components/CancelPayment";

import { useTranslation } from "react-i18next";

const ShowPayment = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [refresh, setRefresh] = useState(false);
  const [payment, setPayment] = useState({});
  const [paymentDate, setPaymentDate] = useState("");
  const [customer, setCustomer] = useState({});
  const [product, setProduct] = useState({});
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [metadata, setMetadata] = useState({});

  const cancelCallback = (close, refreshCancel) => {
    if (close) {
      setCancelOpen(false);
    }
    if (refreshCancel) {
      setRefresh(!refresh);
    }
  };
  useEffect(() => {
    const getDtata = async () => {
      try {
        setIsLoading(true);
        const data = await API.get(
          `${process.env.REACT_APP_API_BASE_URL}/payments/${id}`
        );
        setPayment(data.data[0]);
        setPaymentDate(data.data[0].createdAt.date);
        setCustomer(data.data[0].customer);
        setProduct(data.data[0].product);
        setPaymentMethod(data.data[0].paymentMethod);
        setMetadata(data.data[0].metadata);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getDtata();
    return () => {
      setPayment({});
    };
  }, [id, refresh]);

  const handleRefund = async () => {
    var res = prompt(t("paymentsandsubscriptions.refund_msg"));
    if (res !== "REFUND") {
      return;
    }

    try {
      setIsLoading(true);
      const response = await API.post(
        `${process.env.REACT_APP_API_BASE_URL}/payments/${id}/refund`
      );
      alert(response.data.msg);
      setIsLoading(false);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Loading open={isLoading} />
      <Paper style={{ padding: "20px" }}>
        <h2>{t("paymentsandsubscriptions.titledetailspays")}</h2>
        <Grid container spacing={3}>
          <Grid item xs={6} className="border__bottom__gray">
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#333",
              }}
            >
              <FormatCurrency amount={payment.amount} />
            </span>
          </Grid>
          <Grid item xs={6} className="border__bottom__gray align__right">
            {payment.status && payment.status.toLowerCase() !== "succeeded" ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleRefund}
                disabled
              >
                {t("paymentsandsubscriptions.refund")}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleRefund}
              >
                {t("paymentsandsubscriptions.refund")}
              </Button>
            )}{" "}
            {payment.status &&
            (payment.status.toLowerCase() === "requires_payment_method" ||
              payment.status.toLowerCase() === "requires_capture" ||
              payment.status.toLowerCase() === "requires_confirmation" ||
              payment.status.toLowerCase() === "requires_action") ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setCancelOpen(true)}
              >
                {t("paymentsandsubscriptions.cancel")}
              </Button>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6} sm={3} md={3} className="border__bottom__gray">
            <p>{t("paymentsandsubscriptions.date")}</p>
            <p>
              <FormatDate date={paymentDate} />
            </p>
          </Grid>

          <Grid item xs={6} sm={3} md={3} className="border__bottom__gray">
            <p>{t("paymentsandsubscriptions.gateway")}</p>
            <p>
              {payment.getwayId ? (
                <a
                  href={`${process.env.REACT_APP_PUBLIC_STRIPE_BASE_URL}/payments/${payment.getwayId}`}
                  target="_blank"
                  rel="noreferrer"
                  className="router__link"
                  style={{ color: "#3f51b5", fontWeight: "bold" }}
                >
                  {payment.getway}
                </a>
              ) : (
                payment.getway
              )}
            </p>
          </Grid>
          <Grid item xs={6} sm={3} md={3} className="border__bottom__gray">
            <p>{t("paymentsandsubscriptions.payment_method")}</p>
            <p>{paymentMethod ? `${paymentMethod.type}` : ""}</p>
          </Grid>
          <Grid item xs={6} sm={3} md={3} className="border__bottom__gray">
            <p>{t("paymentsandsubscriptions.status")}</p>
            <p>
              <StatusBadge status={payment.status} size="16" />
            </p>
          </Grid>
          <Grid item xs={6} sm={6} md={6} className="border__bottom__gray">
            <p>{t("paymentsandsubscriptions.customer")}</p>
            <p>
              <Link
                to={`/customers/${customer.id}`}
                className="router__link"
                style={{ color: "#3f51b5", fontWeight: "bold" }}
              >
                {customer.name}
              </Link>
            </p>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className="border__bottom__gray">
            <p>{t("paymentsandsubscriptions.product")}</p>
            <p>
              {product ? (
                <Link
                  to={`/products/${product.id}`}
                  className="router__link"
                  style={{ color: "#3f51b5", fontWeight: "bold" }}
                >
                  {product.name}
                </Link>
              ) : (
                ""
              )}
            </p>
          </Grid>

          {metadata
            ? Object.keys(metadata).map((key, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  style={{ textAlign: "center" }}
                >
                  <p>
                    {key === "plateDonorName"
                      ? t("productShow.plateDonorName")
                      : key === "plateWellName"
                      ? t("productShow.plateWellName")
                      : key}
                  </p>
                  <p>
                    <b>{metadata[key]}</b>
                  </p>
                </Grid>
              ))
            : ""}
        </Grid>
      </Paper>
      <CancelPayment
        open={cancelOpen}
        callback={cancelCallback}
        paymentId={payment.id}
      />
    </Container>
  );
};

export default ShowPayment;
