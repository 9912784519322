import isLoggedReducer from "./isLoggedIn";
import loader from "./loader";

import { combineReducers } from "redux";

const allReducers = combineReducers({
  isLogged: isLoggedReducer,
  loader,
});

export default allReducers;
