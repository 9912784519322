export const loggIn = (user) => {
  return {
    type: "SIGN_IN",
    payload: user,
  };
};

export const checkIfLoggedIn = () => {
  return {
    type: "IS_SIGNED_IN",
  };
};

export const loggOut = () => {
  return {
    type: "SIGN_OUT",
  };
};
