import { useState, useEffect } from "react";
import Loading from "../../components/Loading";
import API from "../../utils/API";
import {
  Paper,
  TextField,
  FormControl,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";

const Settings = () => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [headerScripts, setHeaderScripts] = useState("");
  const [footerScripts, setFooterScripts] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [lang, setLang] = useState("");
  const [actualLang, setActualLang] = useState("");

  const handleLangChange = (e) => {
    setLang(e.target.value);
  };

  const handleHeaderScriptChange = (e) => {
    setHeaderScripts(e.target.value);
  };
  const handleFooterScriptChange = (e) => {
    setFooterScripts(e.target.value);
  };
  useEffect(() => {
    if (localStorage.getItem("i18nextLng")) {
      setActualLang(localStorage.getItem("i18nextLng"));
    }
    const getData = async () => {
      setIsLoading(true);
      try {
        const res = await API.get(
          `${process.env.REACT_APP_PUBLIC_BASE_URL}/settings`
        );
        // console.log(res.data);
        const response = res.data.scripts;
        response.forEach((el, index) => {
          if (el.location === "header") {
            setHeaderScripts(el.value);
          }
          if (el.location === "footer") {
            setFooterScripts(el.value);
          }
        });

        // var responseUrls = res.data.notifUrls;
        // setNotifUrls(responseUrls);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getData();
    return () => {
      setHeaderScripts("");
      setFooterScripts("");
    };
  }, [refresh]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const res = await API.post(
        `${process.env.REACT_APP_API_BASE_URL}/settings/scripts`,
        {
          scriptHeader: headerScripts,
          scriptFooter: footerScripts,
        }
      );

      if (res.data.code === 200) {
        alert(res.data.msg);
        setRefresh(!refresh);
      } else {
        alert(res.data.msg);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const handleLangSubmit = async (e) => {
    e.preventDefault();
    // console.log(e);
    i18n.changeLanguage(lang);

    setActualLang(lang);
    localStorage.setItem("i18nextLng", lang);
  };
  return (
    <>
      <div>
        <h2>{t("settings.block1title")}</h2>
        <Paper style={{ padding: "40px 20px 20px" }}>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth={true} className="form__control_margin">
              <TextField
                id="script-header"
                label="Script Header"
                name="script_header"
                value={headerScripts}
                onChange={handleHeaderScriptChange}
                multiline
                rows={10}
                variant="outlined"
              />
            </FormControl>
            <FormControl fullWidth={true} className="form__control_margin">
              <TextField
                id="script-footer"
                label="Script Footer"
                name="script_footer"
                value={footerScripts}
                onChange={handleFooterScriptChange}
                multiline
                rows={10}
                variant="outlined"
              />
            </FormControl>
            <FormControl fullWidth={true} className="form__control_margin">
              <Button variant="contained" color="primary" type="submit">
                {t("global.update")}
              </Button>
            </FormControl>
          </form>
        </Paper>
      </div>

      <div>
        <h2>{t("settings.language")}</h2>
        <Paper style={{ padding: "40px 20px 20px" }}>
          <form onSubmit={handleLangSubmit}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={6}>
                <FormControl
                  className="form__control_margin"
                  style={{ width: "50%" }}
                >
                  <InputLabel id="select-lang-label">
                    {t("settings.languages")}
                  </InputLabel>
                  <Select
                    labelId="select-lang-label"
                    id="select-lang"
                    value={lang}
                    onChange={handleLangChange}
                  >
                    <MenuItem value={"fr"}>{t("settings.frensh")}</MenuItem>
                    <MenuItem value={"en"}>{t("settings.english")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  className="form__control_margin"
                  style={{ width: "50%" }}
                >
                  {t("settings.active_language")} : {actualLang}
                </FormControl>
              </Grid>
            </Grid>
            <FormControl fullWidth={true} className="form__control_margin">
              <Button variant="contained" color="primary" type="submit">
                {t("global.update")}
              </Button>
            </FormControl>
          </form>
        </Paper>
      </div>
      <Loading open={isLoading} />
    </>
  );
};

export default Settings;
