import { useState, useEffect } from "react";

import { useParams } from "react-router";
import { Button, Container, Grid, Paper } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Loading from "../../../components/Loading";
import API from "../../../utils/API";
import FormatCurrency from "../../../components/FormatCurrency";
import FormatDate from "../../../components/FormatDate";
import { Link } from "react-router-dom";
import StatusBadge from "../../../components/StatusBadge";
import UpdatePaymentMethod from "../../../components/UpdatePaymentMethod";
import PauseSubscription from "../../../components/PauseSubscription";

import { useTranslation } from "react-i18next";

const ShowSubscription = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [subscription, setSubscription] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [updatePMOpen, setUpdatePMOpen] = useState(false);
  const [pauseSubOpen, setPauseSubOpen] = useState(false);
  useEffect(() => {
    const getDtata = async () => {
      try {
        setIsLoading(true);
        const data = await API.get(
          `${process.env.REACT_APP_API_BASE_URL}/subscriptions/${id}`
        );

        setSubscription(data.data[0]);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getDtata();
    return () => {
      setSubscription({});
    };
  }, [id, refresh]);

  const handleCancel = async () => {
    const id = subscription.id;
    var promptMsg = prompt(t("paymentsandsubscriptions.cancel_msg"));
    if (promptMsg === "CANCEL") {
      try {
        setIsLoading(true);
        const res = await API.post(
          `${process.env.REACT_APP_API_BASE_URL}/subscriptions/${id}/cancel`
        );
        alert(res.data.msg);
        setIsLoading(false);
        setRefresh(true);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  };
  const handlePaymentMethodCallback = (close, refreshPM) => {
    if (close) {
      setUpdatePMOpen(false);
    }
    if (refreshPM) {
      setRefresh(!refresh);
    }
  };

  const handlePauseSubscriptionCallback = (close, refreshPS) => {
    if (close) {
      setPauseSubOpen(false);
    }
    if (refreshPS) {
      setRefresh(!refresh);
    }
  };

  const handleUpdatePaymentMethod = () => {
    setUpdatePMOpen(true);
  };
  const handlePauseSubscription = () => {
    setPauseSubOpen(true);
  };
  const handleResumeSubscription = async () => {
    var promptMsg = prompt(t("paymentsandsubscriptions.resume_msg"));
    if (promptMsg === "RESUME") {
      try {
        setIsLoading(true);
        const res = await API.post(
          `${process.env.REACT_APP_API_BASE_URL}/subscriptions/${id}/resume-subscription`
        );
        alert(res.data.msg);
        setRefresh(true);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  };
  return (
    <Container>
      <Loading open={isLoading} />
      <Paper style={{ padding: "20px" }}>
        <h2>{t("paymentsandsubscriptions.titledetailssubs")}</h2>
        <Grid container spacing={3}>
          <Grid item xs={4} className="border__bottom__gray">
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#333",
              }}
            >
              <FormatCurrency amount={subscription.amount} />
            </span>
          </Grid>
          <Grid item xs={8} className="border__bottom__gray align__right">
            {(subscription.status &&
              subscription.status.toLowerCase() !== "active") ||
            subscription.paused ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleCancel}
                  disabled
                >
                  {t("paymentsandsubscriptions.cancel")}
                </Button>{" "}
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleUpdatePaymentMethod}
                  disabled
                >
                  {t("paymentsandsubscriptions.updatepm")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleCancel}
                >
                  {t("paymentsandsubscriptions.cancel")}
                </Button>{" "}
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleUpdatePaymentMethod}
                >
                  {t("paymentsandsubscriptions.updatepm")}
                </Button>
              </>
            )}{" "}
            {subscription.status &&
            (subscription.status.toLowerCase() === "active" ||
              subscription.status.toLowerCase() === "paused") ? (
              !subscription.paused ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handlePauseSubscription}
                >
                  {t("paymentsandsubscriptions.pausesub")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleResumeSubscription}
                >
                  {t("paymentsandsubscriptions.resumesub")}
                </Button>
              )
            ) : !subscription.paused ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handlePauseSubscription}
                disabled
              >
                {t("paymentsandsubscriptions.pausesub")}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleResumeSubscription}
                disabled
              >
                {t("paymentsandsubscriptions.resumesub")}
              </Button>
            )}
          </Grid>
          <Grid item xs={6} sm={3} md={3} className="border__bottom__gray">
            <p>{t("paymentsandsubscriptions.date")}</p>
            <p>
              <FormatDate
                date={subscription.createdAt ? subscription.createdAt.date : ""}
              />
            </p>
          </Grid>

          <Grid item xs={6} sm={3} md={3} className="border__bottom__gray">
            <p>{t("paymentsandsubscriptions.gateway")}</p>
            <p>
              {subscription.getway ? (
                <a
                  href={`${process.env.REACT_APP_PUBLIC_STRIPE_BASE_URL}/subscriptions/${subscription.getwayId}`}
                  target="_blank"
                  rel="noreferrer"
                  className="router__link"
                  style={{ color: "#3f51b5", fontWeight: "bold" }}
                >
                  {subscription.getway}
                </a>
              ) : (
                subscription.getway
              )}
            </p>
          </Grid>
          <Grid item xs={6} sm={3} md={3} className="border__bottom__gray">
            <p>{t("paymentsandsubscriptions.payment_method")}</p>
            <p>
              {subscription.paymentMethod
                ? `${subscription.paymentMethod.type} - ••••${subscription.paymentMethod.last4}`
                : ""}
            </p>
          </Grid>
          <Grid item xs={6} sm={3} md={3} className="border__bottom__gray">
            <p>{t("paymentsandsubscriptions.status")}</p>
            <p>
              <StatusBadge status={subscription.status} size={16} />
              {subscription.paused && subscription.pauseUntil ? (
                <>
                  {t("paymentsandsubscriptions.until")}{" "}
                  <FormatDate date={subscription.pauseUntil.date} />
                </>
              ) : (
                ""
              )}
            </p>
          </Grid>
          <Grid item xs={6} sm={3} md={3} className="border__bottom__gray">
            <p>{t("paymentsandsubscriptions.customer")}</p>
            <p>
              <Link
                to={`/customers/${
                  subscription.customer ? subscription.customer.id : ""
                }`}
                className="router__link"
                style={{ color: "#3f51b5", fontWeight: "bold" }}
              >
                {subscription.customer ? subscription.customer.name : ""}
              </Link>
            </p>
          </Grid>
          <Grid item xs={6} sm={3} md={3} className="border__bottom__gray">
            <p>{t("paymentsandsubscriptions.product")}</p>
            <p>
              <Link
                to={`/products/${
                  subscription.product ? subscription.product.id : ""
                }`}
                className="router__link"
                style={{ color: "#3f51b5", fontWeight: "bold" }}
              >
                {subscription.product ? subscription.product.name : ""}
              </Link>
            </p>
          </Grid>
          <Grid item xs={6} sm={3} md={3} className="border__bottom__gray">
            <p>{t("paymentsandsubscriptions.count")}</p>
            <p
              style={{
                margin: 0,
                fontSize: "26px",
                lineHeight: "20px",
              }}
            >
              {subscription.count ? (
                subscription.count
              ) : (
                <span
                  style={{
                    color: "#3f51b5",
                    fontWeight: "bold",
                    lineHeight: "20px",
                  }}
                >
                  ∞
                </span>
              )}
            </p>
          </Grid>
          <Grid item xs={6} sm={3} md={3} className="border__bottom__gray">
            <p>{t("paymentsandsubscriptions.step")}</p>
            <p
              style={{
                margin: 0,
                fontSize: "26px",
                lineHeight: "20px",
              }}
            >
              {subscription.step}
            </p>
          </Grid>
          <Grid item xs={6} sm={6} md={6} className="border__bottom__gray">
            <p align="center">
              {t("paymentsandsubscriptions.next_payment_date")}
            </p>
            <p
              align="center"
              style={{
                margin: 0,
                fontSize: "26px",
                lineHeight: "20px",
              }}
            >
              {subscription.nextPaymentAt ? (
                <FormatDate date={subscription.nextPaymentAt.date} />
              ) : (
                "..."
              )}
            </p>
          </Grid>
          <Grid item xs={6} sm={6} md={6} className="border__bottom__gray">
            <p align="center">{t("paymentsandsubscriptions.debit_day")}</p>
            <p
              align="center"
              style={{
                margin: 0,
                fontSize: "26px",
                lineHeight: "20px",
              }}
            >
              {subscription.debitDay}
            </p>
          </Grid>
        </Grid>
      </Paper>
      <Paper style={{ padding: "20px", marginTop: "20px" }}>
        <h2>{t("global.payments")}</h2>
        <table style={{ width: "100%", tableLayout: "fixed" }}>
          <tbody>
            {subscription.payments && subscription.payments.length > 0 ? (
              subscription.payments.map((payment, index) => (
                <tr key={index}>
                  <td>#{index + 1}</td>
                  <td>{payment.getway}</td>
                  <td>
                    <FormatCurrency amount={payment.amount} />
                  </td>
                  <td>
                    <StatusBadge status={payment.status} />
                  </td>
                  <td>
                    <Link to={`/payments/${payment.id}`}>
                      <VisibilityIcon style={{ fontSize: "14px" }} />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>{t("global.no_payments")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Paper>
      <UpdatePaymentMethod
        open={updatePMOpen}
        callback={handlePaymentMethodCallback}
        subId={subscription.id}
        localcustomerId={subscription.customer ? subscription.customer.id : ""}
      />
      <PauseSubscription
        open={pauseSubOpen}
        callback={handlePauseSubscriptionCallback}
        subId={subscription.id}
      />
    </Container>
  );
};

export default ShowSubscription;
