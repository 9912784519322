import React, { useEffect, useState } from "react";
import { IbanElement } from "@stripe/react-stripe-js";
import "./IbanFormStyles.css";
import { Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import plaque1 from "../../assets/imgs/plaque1.jpg";
import plaque2 from "../../assets/imgs/plaque2.jpg";
import plaque3 from "../../assets/imgs/plaque3.jpg";
import BeninFlag from "../../assets/imgs/drapeau-benin.jpg";
import LifeLogo from "../../assets/imgs/LOGO-LIFE_blanc.png";

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
  base: {
    color: "#32325d",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4",
    },
    ":-webkit-autofill": {
      color: "#32325d",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
    ":-webkit-autofill": {
      color: "#fa755a",
    },
  },
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ["SEPA"],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  placeholderCountry: "FR",
  style: IBAN_STYLE,
};

const Regularity = ({
  regularityType,
  regularityChoices,
  callback,
  debitDateVisibilityCallback,
}) => {
  const handleRadiochange = (e) => {
    if (e.target.value === "monthly") {
      callback(true);
    } else {
      callback(false);
    }
  };
  const handleRegNumChange = (e) => {
    if (e.target.value === "1") {
      debitDateVisibilityCallback(false);
    } else {
      debitDateVisibilityCallback(true);
    }
  };
  if (regularityType === "one_time") {
    return (
      <div className="regularity__radio__container">
        <input
          className="checkoutInput"
          type="text"
          name="regularitySet"
          value="one_time"
          onChange={() => ""}
          hidden
        />
      </div>
    );
  } else if (regularityType === "monthly_specific_number") {
    return (
      <div className="regularity__radio__container">
        <div className="form-row">
          <center style={{ margin: "auto" }}>
            <input
              type="text"
              name="regularitySet"
              value="monthly_specific_number"
              onChange={() => ""}
              hidden
            />
            <span>Payez en: </span>
            {regularityChoices.map((el, index) => (
              <span className="radio__labelinput__container" key={el}>
                <input
                  type="radio"
                  id={"regularityNum" + index}
                  name="regularityNum"
                  value={el}
                  onChange={handleRegNumChange}
                />
                <label htmlFor={"regularityNum" + index}> {el}X </label>
              </span>
            ))}
          </center>
        </div>
      </div>
    );
  } else if (regularityType === "monthly") {
    return (
      <div className="regularity__radio__container">
        <input
          className="checkoutInput"
          type="text"
          name="regularitySet"
          value="monthly"
          onChange={() => ""}
          hidden
        />
      </div>
    );
  } else if (regularityType === "user_choice") {
    return (
      <div className="regularity__radio__container">
        <div className="form-row">
          <center style={{ margin: "auto" }}>
            <input
              type="text"
              name="regularitySet"
              value="user_choice"
              onChange={() => ""}
              hidden
            />
            <span>
              <strong>Paiement:</strong>{" "}
            </span>
            <span className="radio__labelinput__container">
              <input
                type="radio"
                id={"userchoice1"}
                name="userchoicereg"
                value="one_time"
                onChange={handleRadiochange}
              />
              <label htmlFor={"userchoice1"}>Ponctuel</label>
            </span>
            <span className="radio__labelinput__container">
              <input
                type="radio"
                id={"userchoice2"}
                name="userchoicereg"
                value="monthly"
                onChange={handleRadiochange}
              />
              <label htmlFor={"userchoice2"}>Mensuel</label>
            </span>
          </center>
        </div>
      </div>
    );
  }
  return "";
};

const SelectDebitDate = ({ visible }) => {
  const [arrayDays, setArrayDays] = useState([]);
  useEffect(() => {
    const monthDays = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    ).getDate();

    var arrayDaystoSet = [];

    for (let i = 1; i <= monthDays; i++) {
      arrayDaystoSet.push(i);
    }
    setArrayDays(arrayDaystoSet);
  }, []);

  if (visible) {
    return (
      <label className="formEl" style={{ textAlign: "center" }}>
        Date de vos prélèvements automatique
        <br />
        Le{" "}
        <select
          defaultValue={new Date().getDate()}
          name="debitdate"
          style={{ minWidth: "100px" }}
        >
          {arrayDays.map((e, index) => (
            <option key={index} value={e}>
              {e}
            </option>
          ))}
        </select>{" "}
        de chaque mois
      </label>
    );
  } else {
    return "";
  }
};

export default function IbanForm({ onSubmit, disabled, data }) {
  const [selectDebitDateVisible, setSelectDebitDateVisible] = useState(false);
  useEffect(() => {
    if (data.regularity === "monthly") {
      setSelectDebitDateVisible(true);
    }
  }, [data]);

  const regCallback = (visible) => {
    setSelectDebitDateVisible(visible);
  };
  const handleDebitDateVisibilityCallback = (visible) => {
    setSelectDebitDateVisible(visible);
  };

  const [closePopupPlusOpen, setClosePopupPlusOpen] = useState(false);
  const closePopupPlus = () => {
    setClosePopupPlusOpen(false);
  };
  const openPopupPlus = (e) => {
    e.preventDefault();
    setClosePopupPlusOpen(true);
  };

  const [donorName, setDonorName] = useState("Anonyme");
  const [wellName, setWellName] = useState("AL SALAM");
  const [msgError, setMsgError] = useState("");
  return (
    <form id="checkout__form" onSubmit={onSubmit}>
      <h1 align="center">{data.productName}</h1>
      <h2 align="center" style={{ display: "flex" }}>
        {data.price !== "free_amount" ? (
          <span style={{ margin: "auto 0 auto auto", fontSize: "18px" }}>
            {data.price}
          </span>
        ) : (
          <>
            <span style={{ margin: "auto 0 auto auto", fontSize: "18px" }}>
              Montant:{" "}
            </span>
            <input
              type="number"
              className="checkoutInput"
              id="donationFreeAmount"
              name="donationFreeAmount"
              placeholder="0"
              required
              style={{ width: "20%", margin: "0" }}
            />
          </>
        )}
        {data.currency === "eur" ? (
          <span style={{ margin: "auto auto auto 0", fontSize: "18px" }}>
            € {data.regularity === "monthly" ? " /mois" : ""}
          </span>
        ) : data.currency === "usd" ? (
          <span style={{ margin: "auto auto auto 0", fontSize: "18px" }}>
            $ {data.regularity === "monthly" ? " /mois" : ""}
          </span>
        ) : (
          ""
        )}
      </h2>
      <Regularity
        callback={regCallback}
        regularityType={data.regularity}
        regularityChoices={data.regularityChoices}
        debitDateVisibilityCallback={handleDebitDateVisibilityCallback}
      />
      <div className="form-row">
        {/* <div className="col">
          <label htmlFor="accountholder-name" className="formEl">
            Nom et prénom
          </label>
          <input
            className="checkoutInput"
            id="accountholder-name"
            name="accountholder-name"
            placeholder="Jenny Rosen"
            required
          />
        </div> */}

        <div className="col">
          <label htmlFor="accountholder-firstname" className="formEl">
            Prénom
          </label>
          <input
            className="checkoutInput"
            id="accountholder-firstname"
            name="accountholder-firstname"
            placeholder="Jenny"
            required
          />
        </div>
        <div className="col">
          <label htmlFor="accountholder-lastname" className="formEl">
            Nom
          </label>
          <input
            className="checkoutInput"
            id="accountholder-lastname"
            name="accountholder-lastname"
            placeholder="Rosen"
            required
          />
        </div>
      </div>
      <div className="form-row">
        <label htmlFor="email" className="formEl">
          Email
          <input
            className="checkoutInput"
            id="email"
            name="email"
            type="email"
            placeholder="jenny.rosen@example.com"
            required
          />
        </label>
      </div>
      <div className="form-row">
        <label className="formEl">
          Numéro de télephone
          <input className="checkoutInput" name="phone" type="phone" required />
        </label>
      </div>

      <div className="form-row">
        <div className="col">
          <label htmlFor="city" className="formEl">
            Ville
          </label>
          <input
            type="text"
            id="city"
            name="city"
            className="formEl checkoutInput"
          />
        </div>

        <div className="col">
          <label htmlFor="country" className="formEl">
            Pays
          </label>
          <input
            id="country"
            name="country"
            type="text"
            className="formEl checkoutInput"
          />
        </div>

        <div className="col">
          <label htmlFor="postalcode" className="formEl">
            Code postal
          </label>
          <input
            id="postalcode"
            name="postalcode"
            type="text"
            className="formEl checkoutInput"
          />
        </div>
      </div>

      <div className="form-row">
        <label className="formEl">
          Adresse
          <input
            className="checkoutInput"
            name="address"
            type="text"
            required
          />
        </label>
      </div>

      <div className="form-row">
        <label className="formEl">
          IBAN
          <IbanElement
            options={IBAN_ELEMENT_OPTIONS}
            className="formEl"
            onChange={(event) => {
              if (event.error) {
                console.log(event.error.message);
              }
            }}
          />
        </label>
      </div>

      <div className="form-row">
        <SelectDebitDate visible={selectDebitDateVisible} />
      </div>

      {data.plateDonorWellName ? (
        <div>
          {closePopupPlusOpen ? (
            <PopupPlus closePopupPlusCallback={closePopupPlus} />
          ) : (
            ""
          )}
          <div className="frorm-row">
            <p>
              <b>Personnalisation de la plaque puits</b>{" "}
            </p>
            <p>
              Vous pouvez choisir le nom du puits et du donateurs qui seront
              inscrits sur la plaque du puits, cliquez{" "}
              <span
                style={{
                  cursor: "pointer",
                  color: data.pca,
                  textDecoration: "underline",
                }}
                onClick={openPopupPlus}
              >
                ici
              </span>{" "}
              pour en savoir plus.
            </p>
          </div>
          <div className="form-row">
            <label className="formEl">
              Nom donateur
              <input
                className="checkoutInput"
                name="plateDonorName"
                type="text"
                required
                value={donorName}
                onChange={(e) => {
                  if (e.target.value.length <= 25) {
                    setDonorName(e.target.value);
                    setMsgError("");
                  } else {
                    setMsgError("Nom donateur : 25 caractères maximum");
                  }
                }}
              />
            </label>
            <label className="formEl">
              Nom du puits
              <input
                className="checkoutInput"
                name="plateWellName"
                type="text"
                required
                value={wellName}
                onChange={(e) => {
                  if (e.target.value.length <= 25) {
                    setWellName(e.target.value);
                    setMsgError("");
                  } else {
                    setMsgError("Nom de puits: 25 caractères maximum");
                  }
                }}
              />
            </label>
          </div>
          {msgError ? (
            <div className="msg__error">
              <ErrorIcon /> {msgError}
            </div>
          ) : (
            ""
          )}

          <div align="center">
            <div align="center" className="plate__visual">
              <img width="80px" src={LifeLogo} alt="Logo LIFE-ONG" />
              <p className="plate__well__name">
                Puits <span>{wellName}</span>
              </p>
              <p>
                <b>Donateur : {donorName}</b>
              </p>
              <p>
                <b>Pays : BENIN</b>
              </p>
              <p>
                <b>Localité : KATAGON CENTRE</b>
              </p>
              <fieldset>
                Code d'identification : 21-BENIN03-PA-09
                <br />
                Date de réalisation : 09/2021 <br />
                Profondeur : 35 mètres <br />
              </fieldset>
              <p className="plate__well__important">IMPORTANT</p>
              <div className="plate__well__important__note">
                <div className="plate__well__important__note__flag">
                  <div>
                    <b>BENIN</b>
                  </div>
                  <img src={BeninFlag} alt="Benin Flag" />
                </div>
                <div className="plate__well__important__note__text">
                  La source est le bien public de tous les habitants. "C' est le
                  souci d'équité entre les citoyens qui gouvernera la
                  répartition de la ressource en eau, indépendamment des
                  origines ethniques, religieuses ou sociales" (art. 8 du CGE)
                </div>
                {/* <div className="plate__well__important__note__text_end">
                  <b>ASEP ONG</b>
                </div> */}
              </div>
            </div>
          </div>
          <p className="important__note">
            NB : Ceci est une illustration de plaque, les informations inscrites
            n'y figurent qu'à titre d'exemple
          </p>
        </div>
      ) : (
        ""
      )}

      <Button
        color="primary"
        variant="contained"
        type="submit"
        disabled={disabled}
        fullWidth
        style={data.pca ? { backgroundColor: data.pca } : {}}
      >
        JE DONNE
      </Button>

      {/* Display mandate acceptance text. */}
      <div className="mandate-acceptance">
        <p>
          En fournissant vos informations de paiement et en confirmant ce
          paiement, vous autoriser (A) LIFE and Stripe, notre prestataire de
          services de paiement, à envoyer instructions à votre banque pour
          débiter votre compte et (B) votre banque à débiter votre compte
          conformément à ces instructions. Dans le cadre de vos droits, vous
          avez droit à un remboursement de votre banque au titre du les termes
          et conditions de votre accord avec votre banque. Un remboursement doit
          être réclamé dans les 8 semaines à compter de la date à laquelle votre
          compte a été débité. Vos droits sont expliqués dans une déclaration
          que vous pouvez obtenir de votre banque. Vous acceptez de recevoir des
          notifications pour l'avenir prélèvements jusqu'à 2 jours avant qu'ils
          ne surviennent.
        </p>
      </div>
    </form>
  );
}

const popupStyle = {
  container: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: "9",
    // backgroundColor: "rgba(0,0,0,0.4)",
    display: "flex",
    height: "100%",
  },
  containerInner: {
    margin: "auto",
    padding: "20px",
    borderRadius: "4px",
    width: "500px",
    maxWidth: "95%",
    backgroundColor: "white",
    position: "relative",
    zIndex: "999",
    maxHeight: "90vh",
    overflow: "auto",
  },
  closeOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: "99",
    backgroundColor: "rgba(0,0,0,0.4)",
  },
  closeIconContainer: {
    position: "absolute",
    right: "15px",
    top: "15px",
    cursor: "pointer",
    color: "red",
  },
};
const PopupPlus = ({ closePopupPlusCallback }) => {
  const closeHandler = () => {
    closePopupPlusCallback();
  };

  return (
    <div style={popupStyle.container}>
      <div style={popupStyle.closeOverlay} onClick={closeHandler}></div>
      <div style={popupStyle.containerInner}>
        <div
          className="close__container"
          style={popupStyle.closeIconContainer}
          onClick={closeHandler}
        >
          <CloseIcon />
        </div>
        <h3>
          Tu es à cours d'idées à propos des mentions plaques de ton projet de
          puits?
        </h3>
        <p>
          <b>
            Tu trouveras ici quelques suggestions d'idées afin de t'aider à
            faire ton choix :
          </b>
        </p>
        <p>
          - <b>Nom du puits :</b> (Alphabet latin, 25 caractères maximum)
          exemple de nom : Puits du partage, Puits de la miséricorde, Puits
          Ar-Rahman, Bismillah, Puits Ar-razzaq, Bir er Rahma, etc...
          <br />
          <br />- <b>Mention donateur :</b> (Alphabet latin, 25 caractères
          maximum) exemple de mentions : votre frère, votre serviteur, Quelqu'un
          qui pense à vous, nom et prénom du donateur, nom et prénom de la
          personne défunte (dans le cas où le puits a été réalisé en l'honneur
          d'un proche défunt), etc...
        </p>
        <div className="plate__container">
          <div className="plate__image__container">
            <img
              className="plate__image"
              src={plaque1}
              alt="Plaque 1 exemple"
            />
          </div>
          <div className="plate__image__container">
            <img
              className="plate__image"
              src={plaque2}
              alt="Plaque 2 exemple"
            />
          </div>
          <div className="plate__image__container">
            <img
              className="plate__image"
              src={plaque3}
              alt="Plaque 3 exemple"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
