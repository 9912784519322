import React from "react";

import {
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  IconButton,
  Hidden,
  Drawer,
  Divider,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import GroupIcon from "@material-ui/icons/Group";
import ClassIcon from "@material-ui/icons/Class";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SettingsIcon from "@material-ui/icons/Settings";
import EventIcon from "@material-ui/icons/Event";

import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { indigo, pink } from "@material-ui/core/colors";

import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { loggOut } from "../providers/actions";

import { useTranslation } from "react-i18next";

const theme = createTheme({
  palette: {
    primary: indigo,
    secondary: pink,
  },
});

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    marginTop: "64px",
    padding: theme.spacing(3),
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
}));

const DefaultLayout = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { window, children } = props;
  const classes = useStyles();
  //const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenuAccount = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuAccountClose = () => {
    setAnchorEl(null);
  };
  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Typography
          variant="h6"
          noWrap
          style={{
            color: "#3f51b5",
            width: "100%",
            textAlign: "center",
            height: "64px",
            display: "flex",
          }}
        >
          <Link to="/" className={classes.link} style={{ margin: "auto" }}>
            LIFE Donate
          </Link>
        </Typography>
      </div>
      <Divider />
      <List>
        <Link to="/" className={classes.link}>
          <ListItem button key={"Dashboard"}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
        </Link>
        <Link to="/products" className={classes.link}>
          <ListItem button key={"Products"}>
            <ListItemIcon>
              <AllInboxIcon />
            </ListItemIcon>
            <ListItemText primary={t("global.products")} />
          </ListItem>
        </Link>
        <Link to="/customers" className={classes.link}>
          <ListItem button key={"Customers"}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={t("global.customers")} />
          </ListItem>
        </Link>
        <Link to="/campaigns" className={classes.link}>
          <ListItem button key={"Campaigns"}>
            <ListItemIcon>
              <ClassIcon />
            </ListItemIcon>
            <ListItemText primary={t("global.campaigns")} />
          </ListItem>
        </Link>
        <Link to="/payments" className={classes.link}>
          <ListItem button key={"Payments"}>
            <ListItemIcon>
              <MonetizationOnIcon />
            </ListItemIcon>
            <ListItemText primary={t("global.payments")} />
          </ListItem>
        </Link>
        <Link to="/subscriptions" className={classes.link}>
          <ListItem button key={"Subscriptions"}>
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary={t("global.subscriptions")} />
          </ListItem>
        </Link>
        <Link to="/settings" className={classes.link}>
          <ListItem button key={"Settings"}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={t("global.settings")} />
          </ListItem>
        </Link>
        {/* <Link to="/checkout" className={classes.link}>
          <ListItem button key={"Checkout"}>
            <ListItemIcon>
              <AllInboxIcon />
            </ListItemIcon>
            <ListItemText primary={"Checkout"} />
          </ListItem>
        </Link> */}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
              {/* <Link to="/" className={classes.link}>
                LIFE Donate
              </Link> */}
            </Typography>
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuAccount}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleMenuAccountClose}
              >
                <MenuItem
                  onClick={() => {
                    dispatch(loggOut());
                    handleMenuAccountClose();
                  }}
                >
                  {t("global.logout")}
                </MenuItem>
                <MenuItem onClick={handleMenuAccountClose} disabled>
                  {t("global.my_account")}
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden mdUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>{children}</main>
      </div>
    </ThemeProvider>
  );
};

export default DefaultLayout;
