import React, { useEffect, useState } from "react";

import Loading from "../../components/Loading";

import axios from "axios";
import { useHistory, useParams } from "react-router";

import BancontactCheckout from "../../components/BancontactCheckout";

const CheckoutBancontact = () => {
  const { slug } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);



  const [initialData, setInitialData] = useState({
    productName: "",
    price: "",
    regularity: "",
    productGetwayId: "",
    productId: "",
    pca: "",
    plateDonorWellName: "",
  });
  useEffect(() => {
  


    const getHeaderFooterScripts = async () => {
      setIsLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_PUBLIC_BASE_URL}/settings`
      );
      const response = res.data.scripts;

      var headerScriptsValue = "";
      var footerScriptsValue = "";
      response.forEach((element) => {
        if (element.location === "header") headerScriptsValue = element.value;

        if (element.location === "footer") footerScriptsValue = element.value;
      });
      var headers = document.createElement("div");
      headers.innerHTML = headerScriptsValue;
      document.head.appendChild(headers);

      var footers = document.createElement("div");
      footers.innerHTML = footerScriptsValue;
      document.body.appendChild(footers);
    };

    const getData = async () => {
      setIsLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_PUBLIC_BASE_URL}/products/checkout/${slug}`
      );
      if (res.data.product) {
        const response = res.data.product;
        setInitialData({
          productName: response.name,
          productSlug: response.slug,
          price: response.price,
          regularity: response.regularity,
          productGetwayId: response.getwayId,
          productId: response.id,
          currency: response.currency,
          spu: response.successPageUrl,
          pca: response.campaign ? response.campaign.color : "",
          regularityChoices: response.regularityChoices,
          campName: response.campaign ? response.campaign.name : "",
          plateDonorWellName: response.includePlateDonorWellName ? true : false,
        });

        setIsLoading(false);
      } else {
        history.push(`/not-found`);
        setIsLoading(false);
      }
    };
    getData();
    getHeaderFooterScripts();
  }, [slug, history]);


  return (
    <div className="checkout__container">
      <Loading open={isLoading} />
   
      <BancontactCheckout initialData={initialData} />
    </div>
  );
};

export default CheckoutBancontact;
