import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Loading from "../Loading";
import API from "../../utils/API";

import { useTranslation } from "react-i18next";

const PauseSubscription = (props) => {
  const { t } = useTranslation();
  const { open, callback, subId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [pauseType, setPauseType] = useState("undefinite");
  const [selectedDateVidible, setSelectedDateVidible] = useState(false);
  const handlePauseChange = (e) => {
    setPauseType(e.target.value);
    if (e.target.value === "customdate") {
      // show datepicker
      setSelectedDateVidible(true);
    } else {
      //hide date picker
      setSelectedDateVidible(false);
    }
  };
  const [selectedDate, setSelectedDate] = useState(
    new Date().setDate(new Date().getDate() + 1)
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClose = async () => {
    // closedialog = true // refresh = false
    callback(true, false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var dataTosend = {
      pauseType: "undefinite",
      resumeAt: "",
    };
    if (pauseType === "customdate") {
      var datee = new Date(new Date(selectedDate).getTime() + 30 * 60000);
      const d = datee.getDate();
      const m = datee.getMonth() + 1;
      const y = datee.getFullYear();
      const h = datee.getHours();
      const mi = datee.getMinutes();
      const sec = datee.getSeconds();
      dataTosend = {
        pauseType: "customdate",
        resumeAt: `${d}-${m}-${y} ${h}:${mi}:${sec}`,
      };
    }

    try {
      const response = await API.post(
        `${process.env.REACT_APP_API_BASE_URL}/subscriptions/${subId}/pause-subscription`,
        dataTosend
      );
      alert(response.data.msg);
      setIsLoading(false);

      callback(true, true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <Loading open={isLoading} />
      <DialogTitle id="alert-dialog-title">
        {t("paymentsandsubscriptions.pause_sub")}
      </DialogTitle>
      <DialogContent>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <RadioGroup
                aria-label="gender"
                name="pause_type"
                value={pauseType}
                onChange={handlePauseChange}
              >
                <FormControlLabel
                  color="primary"
                  label={t("paymentsandsubscriptions.undefinite")}
                  value="undefinite"
                  control={<Radio />}
                />
                <FormControlLabel
                  color="primary"
                  label={t("paymentsandsubscriptions.until_cd")}
                  value="customdate"
                  control={<Radio />}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={6}>
              {selectedDateVidible ? (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    minDate={new Date().setDate(new Date().getDate() + 1)}
                    margin="normal"
                    id="until-date"
                    name="until_date"
                    label={t("paymentsandsubscriptions.until_date")}
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <FormControl fullWidth={true} className="form__control_margin">
            <Button variant="contained" color="primary" type="submit">
              {t("global.update")}
            </Button>
          </FormControl>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PauseSubscription;
