import { useState } from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "./main.css";

import { useTranslation } from "react-i18next";

const SearchDateInterval = ({ callback }) => {
  const { t } = useTranslation();
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);

  const handleDateFromChange = (date) => {
    setSelectedDateFrom(date);
  };
  const handleDateToChange = (date) => {
    setSelectedDateTo(date);
  };

  const handleDateSubmit = () => {
    if (selectedDateFrom === null || selectedDateTo === null) {
      alert("Please select date From and date to");
      return false;
    }

    if (selectedDateFrom > selectedDateTo) {
      alert("Date To must be supperior than Date From");
      return false;
    }

    callback(selectedDateFrom, selectedDateTo);
  };
  return (
    <div className="search__container">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Paper className="paper__container">
          <Grid align="center" alignItems="center" container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline-from"
                label={t("global.from")}
                value={selectedDateFrom}
                onChange={handleDateFromChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline-to"
                label={t("global.to")}
                value={selectedDateTo}
                onChange={handleDateToChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleDateSubmit}
              >
                {t("global.search")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default SearchDateInterval;
