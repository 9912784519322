import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import Loading from "../Loading";
import API from "../../utils/API";

import { useTranslation } from "react-i18next";

const CancelPayment = (props) => {
  const { t } = useTranslation();
  const { open, callback, paymentId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");

  const handleCRChange = (e) => {
    setCancellationReason(e.target.value);
  };

  const handleClose = () => {
    // closedialog = true // refresh = false
    callback(true, false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!cancellationReason) {
      alert("Cancellation reason is required");
      setIsLoading(false);
      return false;
    }

    try {
      const res = await API.post(
        `${process.env.REACT_APP_API_BASE_URL}/payments/${paymentId}/cancel`,
        {
          cancellationReason: cancellationReason,
        }
      );
      alert(res.data.msg);
      if (res.data.status === 400) {
        setIsLoading(false);
        return false;
      }
      callback(true, true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <Loading open={isLoading} />
      <DialogTitle id="alert-dialog-title">
        {t("paymentsandsubscriptions.cancel_pay_title")}
      </DialogTitle>
      <DialogContent>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <FormControl
            required
            fullWidth={true}
            className="form__control_margin"
          >
            <InputLabel id="payment-method-type-label">
              {t("paymentsandsubscriptions.cancel_reason")}
            </InputLabel>
            <Select
              labelId="payment-method-type-label"
              id="select_pm_type"
              value={cancellationReason}
              onChange={handleCRChange}
              required
            >
              <MenuItem value={"duplicate"}>
                {t("paymentsandsubscriptions.duplicate")}
              </MenuItem>
              <MenuItem value={"fraudulent"}>
                {t("paymentsandsubscriptions.fraudulent")}
              </MenuItem>
              <MenuItem value={"requested_by_customer"}>
                {t("paymentsandsubscriptions.rby_customer")}
              </MenuItem>
              <MenuItem value={"abandoned"}>
                {t("paymentsandsubscriptions.abandoned")}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth={true} className="form__control_margin">
            <Button variant="contained" color="primary" type="submit">
              Cancel
            </Button>
          </FormControl>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CancelPayment;
