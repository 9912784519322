import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import Loading from "../Loading";
import API from "../../utils/API";

import { useTranslation } from "react-i18next";

const UpdatePaymentMethod = (props) => {
  const { t } = useTranslation();
  const { open, callback, subId, localcustomerId } = props;
  const [ibanNum, setIbanNum] = useState("");
  const [cardNum, setCardNum] = useState("");
  const [cardVisible, setCardVisible] = useState(false);
  const [ibanVisible, setIbanVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [pmType, setPmType] = useState("sepa_debit");
  const handleClose = () => {
    // closedialog = true // refresh = false
    callback(true, false);
  };

  const handlePmTypeChange = (e) => {
    setPmType(e.target.value);
    if (e.target.value === "sepa_debit") {
      setIbanVisible(true);
      setCardVisible(false);
    } else if (e.target.value === "card") {
      setCardVisible(true);
      setIbanVisible(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var accountNum = "";
    if (pmType === "sepa_debit") {
      accountNum = ibanNum;
    } else if (pmType === "card") {
      accountNum = cardNum;
    }

    setIsLoading(true);
    try {
      // fetch HERE
      const res = await API.post(
        `${process.env.REACT_APP_API_BASE_URL}/subscriptions/${subId}/update-pm`,
        {
          accountNum,
          pmType,
          localcustomerId,
        }
      );
      alert(res.data.msg);
      if (res.data.status === 400) {
        setIsLoading(false);
        return false;
      }
      callback(true, true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }

    // if all OK => callback(true, true);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <Loading open={isLoading} />
      <DialogTitle id="alert-dialog-title">
        {t("paymentsandsubscriptions.update_pm")}
      </DialogTitle>
      <DialogContent>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <FormControl fullWidth={true} className="form__control_margin">
            <InputLabel id="payment-method-type-label">
              {t("paymentsandsubscriptions.update_pm")}
            </InputLabel>
            <Select
              labelId="payment-method-type-label"
              id="select_pm_type"
              value={pmType}
              onChange={handlePmTypeChange}
            >
              <MenuItem value={"sepa_debit"}>SEPA Direct debit</MenuItem>
              <MenuItem value={"card"} disabled>
                Card (CB)
              </MenuItem>
            </Select>
          </FormControl>
          {ibanVisible ? (
            <FormControl fullWidth={true} className="form__control_margin">
              <TextField
                id="ibannum"
                name="ibanNumber"
                label="IBAN"
                value={ibanNum}
                onChange={(e) => setIbanNum(e.target.value)}
              />
            </FormControl>
          ) : (
            ""
          )}

          {cardVisible ? (
            <FormControl fullWidth={true} className="form__control_margin">
              <TextField
                id="cardnum"
                name="cardnumber"
                label="Card Number"
                value={cardNum}
                onChange={(e) => setCardNum(e.target.value)}
              />
            </FormControl>
          ) : (
            ""
          )}

          <FormControl fullWidth={true} className="form__control_margin">
            <Button variant="contained" color="primary" type="submit">
              {t("global.update")}
            </Button>
          </FormControl>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdatePaymentMethod;
