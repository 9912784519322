import { useState, useEffect } from "react";

import Table from "../../components/Table";
import Loading from "../../components/Loading";
import API from "../../utils/API";

import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

import { useTranslation } from "react-i18next";

const Products = () => {
  const { t } = useTranslation();

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const res = await API.get(`/products`);

        const products = res.data.map((sdata) => {
          sdata.createdAt = sdata.createdAt.date;

          if (sdata.regularity === "monthly_specific_number") {
            sdata.regularity = "Monthly (with choice)";
          } else if (sdata.regularity === "one_time") {
            sdata.regularity = "One Time";
          } else if (sdata.regularity === "monthly") {
            sdata.regularity = "Monthly";
          } else if (sdata.regularity === "user_choice") {
            sdata.regularity = "User Choice (Monthly / One Time)";
          }

          return sdata;
        });
        setData(products);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    };
    loadData();

    setColumns([
      { title: t("products.name"), field: "name" },
      { title: t("products.campaign"), field: "campaign.name" },
      { title: t("products.price"), field: "price" },
      { title: t("products.currency"), field: "currency" },
      { title: t("products.regularity"), field: "regularity" },
      {
        title: t("products.created_at"),
        field: "createdAt",
        type: "date",
      },
    ]);
  }, [t]);

  return (
    <Container maxWidth="xl">
      <Loading open={isLoading} />
      <div align="right" style={{ marginBottom: "20px" }}>
        <Link to="/products/new" className="router__link">
          <Button variant="contained" color="primary">
            {t("products.add_new")}
          </Button>
        </Link>
      </div>
      <Table
        tableTitle={t("products.title")}
        columns={columns}
        data={data}
        actions={["delete", "edit", "details", "preview", "copy"]}
        apiUrl={`${process.env.REACT_APP_API_BASE_URL}/products`}
        baseUrl={"/products/"}
      />
    </Container>
  );
};

export default Products;
