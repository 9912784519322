import { useState, useEffect } from "react";

import Table from "../../components/Table";
import Loading from "../../components/Loading";
import API from "../../utils/API";
import FormatCurrency from "../../components/FormatCurrency";
import FormatDate from "../../components/FormatDate";
import StatusBadge from "../../components/StatusBadge";
import SearchDateInterval from "../../components/SearchDateInterval";

import { useTranslation } from "react-i18next";

const Subscriptions = () => {
  const { t } = useTranslation();
  const [subscriptions, setSubscriptions] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getSubscriptions = async () => {
      try {
        setIsLoading(true);
        const data = await API.get(
          `${process.env.REACT_APP_API_BASE_URL}/subscriptions`
        );
        const subs = data.data;
        subs.map((sub, index) => {
          if (sub.payments.length) {
            sub.payments = [sub.payments[sub.payments.length - 1]];
            sub.payments[0].status = (
              <StatusBadge status={sub.payments[0].status} size={11} />
            );
            sub.payments[0].createdAt = (
              <FormatDate date={sub.payments[0].createdAt.date} />
            );
          }
          sub.amount = <FormatCurrency amount={sub.amount} />;
          sub.getwayStartDate = <FormatDate date={sub.getwayStartDate} />;
          sub.createdAt = <FormatDate date={sub.createdAt.date} />;
          sub.count = sub.count ? sub.count : "∞";
          sub.status = <StatusBadge status={sub.status} size={11} />;
          return sub;
        });
        setSubscriptions(subs);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getSubscriptions();
    setColumns([
      { title: t("tablecolumns.name"), field: "customer.name" },
      { title: t("tablecolumns.email"), field: "customer.email" },
      { title: t("tablecolumns.product"), field: "product.name" },
      { title: t("tablecolumns.amount"), field: "amount" },
      { title: t("tablecolumns.debit_day"), field: "debitDay" },
      { title: t("tablecolumns.count"), field: "count" },
      { title: t("tablecolumns.step"), field: "step" },
      { title: t("tablecolumns.step_status"), field: "payments[0].status" },
      {
        title: t("tablecolumns.created_at"),
        field: "payments[0].createdAt",
        type: "date",
      },
      { title: t("tablecolumns.status"), field: "status" },
      {
        title: t("tablecolumns.started_at"),
        field: "createdAt",
        type: "date",
      },
    ]);
    return () => {
      setSubscriptions([]);
    };
  }, [t]);

  const handleSearchDateCallback = async (from, to) => {
    setIsLoading(true);
    const fromFormated =
      new Date(from).getFullYear() +
      "-" +
      (new Date(from).getMonth() + 1) +
      "-" +
      new Date(from).getDate();

    const toFormated =
      new Date(to).getFullYear() +
      "-" +
      (new Date(to).getMonth() + 1) +
      "-" +
      new Date(to).getDate();

    try {
      const res = await API.get(
        `${process.env.REACT_APP_API_BASE_URL}/subscriptions/${fromFormated}/${toFormated}`
      );
      const subs = res.data;
      subs.map((sub, index) => {
        if (sub.payments.length) {
          sub.payments = [sub.payments[sub.payments.length - 1]];
          sub.payments[0].status = (
            <StatusBadge status={sub.payments[0].status} size={11} />
          );
          sub.payments[0].createdAt = (
            <FormatDate date={sub.payments[0].createdAt.date} />
          );
        }
        sub.amount = <FormatCurrency amount={sub.amount} />;
        sub.getwayStartDate = <FormatDate date={sub.getwayStartDate} />;
        sub.createdAt = <FormatDate date={sub.createdAt.date} />;
        sub.count = sub.count ? sub.count : "∞";
        sub.status = <StatusBadge status={sub.status} size={11} />;
        return sub;
      });
      setSubscriptions(subs);
      setIsLoading(false);
      // console.log(res);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  return (
    <div>
      <Loading open={isLoading} />

      <SearchDateInterval callback={handleSearchDateCallback} />

      <Table
        tableTitle={t("global.subscriptions")}
        columns={columns}
        data={subscriptions}
        actions={["details"]}
        apiUrl={`${process.env.REACT_APP_API_BASE_URL}/subscriptions`}
        baseUrl={"/subscriptions/"}
      />
    </div>
  );
};
export default Subscriptions;
