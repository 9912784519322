import React, { useEffect, useState } from "react";

import Loading from "../../components/Loading";

import axios from "axios";
import { useHistory, useParams } from "react-router";
import PaymentMethodChoice from "../../components/PaymentMethodChoice";
import IbanCheckout from "../../components/IbanCheckout";
import BancontactCheckout from "../../components/BancontactCheckout";

const Checkout = () => {
  const { slug } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [paymentMethodChekout, setPaymentMethodChekout] = useState(null);

  const [initialData, setInitialData] = useState({
    productName: "",
    price: "",
    regularity: "",
    productGetwayId: "",
    productId: "",
    pca: "",
    plateDonorWellName: "",
  });
  useEffect(() => {
    var url = new URL(window.location);
    var clientSecret = url.searchParams.get("payment_intent_client_secret");
    var setupClientSecret = url.searchParams.get("setup_intent_client_secret");
    if (clientSecret || setupClientSecret) {
      setPaymentMethodChekout("BANCONTACT");
    }

    const getHeaderFooterScripts = async () => {
      setIsLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_PUBLIC_BASE_URL}/settings`
      );
      const response = res.data.scripts;

      var headerScriptsValue = "";
      var footerScriptsValue = "";
      response.forEach((element) => {
        if (element.location === "header") headerScriptsValue = element.value;

        if (element.location === "footer") footerScriptsValue = element.value;
      });
      var headers = document.createElement("div");
      headers.innerHTML = headerScriptsValue;
      document.head.appendChild(headers);

      var footers = document.createElement("div");
      footers.innerHTML = footerScriptsValue;
      document.body.appendChild(footers);
    };

    const getData = async () => {
      setIsLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_PUBLIC_BASE_URL}/products/checkout/${slug}`
      );
      if (res.data.product) {
        const response = res.data.product;
        setInitialData({
          productName: response.name,
          productSlug: response.slug,
          price: response.price,
          regularity: response.regularity,
          productGetwayId: response.getwayId,
          productId: response.id,
          currency: response.currency,
          spu: response.successPageUrl,
          pca: response.campaign ? response.campaign.color : "",
          regularityChoices: response.regularityChoices,
          campName: response.campaign ? response.campaign.name : "",
          plateDonorWellName: response.includePlateDonorWellName ? true : false,
        });

        setIsLoading(false);
      } else {
        history.push(`/not-found`);
        setIsLoading(false);
      }
    };
    getData();
    getHeaderFooterScripts();
  }, [slug, history]);

  const handlePaymentMethodCallback = (paymentMethod) => {
    if (paymentMethod === "SEPA") {
      setPaymentMethodChekout("SEPA");
    } else if (paymentMethod === "BANCONTACT") {
      setPaymentMethodChekout("BANCONTACT");
    }
  };
  return (
    <div className="checkout__container">
      <Loading open={isLoading} />
      {paymentMethodChekout && paymentMethodChekout === "SEPA" ? (
        <IbanCheckout initialData={initialData} />
      ) : paymentMethodChekout && paymentMethodChekout === "BANCONTACT" ? (
        <BancontactCheckout initialData={initialData} />
      ) : (
        <PaymentMethodChoice
        link={"/checkout/" + initialData.productSlug }
          paymentMethodCallback={handlePaymentMethodCallback}
        />
      )}
    </div>
  );
};

export default Checkout;
