import React from "react";

import Loader from "./Loader";

const FrontLayout = ({ children }) => {
  return (
    <div>
      <Loader />
      {children}
    </div>
  );
};

export default FrontLayout;
